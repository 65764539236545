export const FETCH_LAWYERS_LIST_START = 'FETCH_LAWYERS_LIST_START';
export const FETCH_LAWYERS_LIST_SUCCESS = 'FETCH_LAWYERS_LIST_SUCCESS';
export const FETCH_LAWYERS_LIST_FAIL = 'FETCH_LAWYERS_LIST_FAIL';

export const FETCH_PUBLIC_LAWYER_START = 'FETCH_PUBLIC_LAWYER_START';
export const FETCH_PUBLIC_LAWYER_SUCCESS = 'FETCH_PUBLIC_LAWYER_SUCCESS';
export const FETCH_PUBLIC_LAWYER_FAIL = 'FETCH_PUBLIC_LAWYER_FAIL';

export const RESET_PUBLIC_LAWYER_STORE = 'RESET_PUBLIC_LAWYER_STORE';

export const FETCH_SIMILAR_LAWYERS_START = 'FETCH_SIMILAR_LAWYERS_START';
export const FETCH_SIMILAR_LAWYERS_SUCCESS = 'FETCH_SIMILAR_LAWYERS_SUCCESS';
export const FETCH_SIMILAR_LAWYERS_FAIL = 'FETCH_SIMILAR_LAWYERS_FAIL';

export const RESET_SIMILAR_LAWYERS_STORE = 'RESET_SIMILAR_LAWYERS_STORE';

export const UPDATE_LAWYER_STATUS_START = 'UPDATE_LAWYER_STATUS_START';
export const UPDATE_LAWYER_STATUS_SUCCESS = 'UPDATE_LAWYER_STATUS_SUCCESS';
export const UPDATE_LAWYER_STATUS_FAIL = 'UPDATE_LAWYER_STATUS_FAIL';

export const CREATE_LAWYER_PROFILE_INFO_START =
  'CREATE_LAWYER_PROFILE_INFO_START';
export const CREATE_LAWYER_PROFILE_INFO_SUCCESS =
  'CREATE_LAWYER_PROFILE_INFO_SUCCESS';
export const CREATE_LAWYER_PROFILE_INFO_FAIL =
  'CREATE_LAWYER_PROFILE_INFO_FAIL';

export const UPDATE_LAWYER_PROFILE_INFO_START =
  'UPDATE_LAWYER_PROFILE_INFO_START';
export const UPDATE_LAWYER_PROFILE_INFO_SUCCESS =
  'UPDATE_LAWYER_PROFILE_INFO_SUCCESS';
export const UPDATE_LAWYER_PROFILE_INFO_FAIL =
  'UPDATE_LAWYER_PROFILE_INFO_FAIL';

export const RESET_LAWYER_PROFILE_INFO_STORE =
  'RESET_LAWYER_PROFILE_INFO_STORE';

export const FETCH_PUBLIC_LAWYERS_LIST_START =
  'FETCH_PUBLIC_LAWYERS_LIST_START';
export const FETCH_PUBLIC_LAWYERS_LIST_SUCCESS =
  'FETCH_PUBLIC_LAWYERS_LIST_SUCCESS';
export const FETCH_PUBLIC_LAWYERS_LIST_FAIL = 'FETCH_PUBLIC_LAWYERS_LIST_FAIL';

export const SET_PROFILE_INFO_STEP_ONE_CATEGORIES =
  'SET_PROFILE_INFO_STEP_ONE_CATEGORIES';

export const SET_PROFILE_INFO_STEP_TWO = 'SET_PROFILE_INFO_STEP_TWO';

export const REQUEST_ACCOUNT_ACTIVATION_START =
  'REQUEST_ACCOUNT_ACTIVATION_START';
export const REQUEST_ACCOUNT_ACTIVATION_SUCCESS =
  'REQUEST_ACCOUNT_ACTIVATION_SUCCESS';
export const REQUEST_ACCOUNT_ACTIVATION_FAIL =
  'REQUEST_ACCOUNT_ACTIVATION_FAIL';

export const RESET_LAWYERS_STORE = 'RESET_LAWYERS_STORE';

export const LOGOUT = 'LOGOUT';

export type LawyerActionTypes =
  | typeof CREATE_LAWYER_PROFILE_INFO_START
  | typeof CREATE_LAWYER_PROFILE_INFO_SUCCESS
  | typeof CREATE_LAWYER_PROFILE_INFO_FAIL
  | typeof UPDATE_LAWYER_PROFILE_INFO_START
  | typeof UPDATE_LAWYER_PROFILE_INFO_SUCCESS
  | typeof UPDATE_LAWYER_PROFILE_INFO_FAIL
  | typeof FETCH_LAWYERS_LIST_START
  | typeof FETCH_LAWYERS_LIST_SUCCESS
  | typeof FETCH_LAWYERS_LIST_FAIL
  | typeof FETCH_PUBLIC_LAWYER_START
  | typeof FETCH_PUBLIC_LAWYER_SUCCESS
  | typeof FETCH_PUBLIC_LAWYER_FAIL
  | typeof FETCH_SIMILAR_LAWYERS_START
  | typeof FETCH_SIMILAR_LAWYERS_SUCCESS
  | typeof FETCH_SIMILAR_LAWYERS_FAIL
  | typeof UPDATE_LAWYER_STATUS_START
  | typeof UPDATE_LAWYER_STATUS_SUCCESS
  | typeof UPDATE_LAWYER_STATUS_FAIL
  | typeof SET_PROFILE_INFO_STEP_ONE_CATEGORIES
  | typeof SET_PROFILE_INFO_STEP_TWO
  | typeof RESET_PUBLIC_LAWYER_STORE
  | typeof RESET_SIMILAR_LAWYERS_STORE
  | typeof RESET_LAWYER_PROFILE_INFO_STORE
  | typeof FETCH_PUBLIC_LAWYERS_LIST_START
  | typeof FETCH_PUBLIC_LAWYERS_LIST_SUCCESS
  | typeof FETCH_PUBLIC_LAWYERS_LIST_FAIL
  | typeof REQUEST_ACCOUNT_ACTIVATION_START
  | typeof REQUEST_ACCOUNT_ACTIVATION_SUCCESS
  | typeof REQUEST_ACCOUNT_ACTIVATION_FAIL
  | typeof RESET_LAWYERS_STORE
  | typeof LOGOUT;
