import { Asset } from './Asset';
import { Category } from './Category';
import { City } from './City';
import { Language } from './Language';

export interface Lawyer {
  id: number;
  createdAt: string;
  name: string;
  email: string;
  phoneNumber: string;
  categories: Category[];
  title: string;
  languages: Language[];
  education: string | null;
  price: string | null;
  lawFirm: string | null;
  lawyerType: LawyerType;
  profilePicture: Asset | null;
  description: string | null;
  workExperience: number;
  cityId: number | null;
  throughoutTheCountry: boolean;
  city: City;
  policies: boolean;
  accountStatus: AccountActivationStatus;
  activeAccountEnd: string;
  freePlanRequested: boolean;
}

export enum LawyerType {
  // eslint-disable-next-line no-unused-vars
  ATTORNEY = 'ATTORNEY',
  // eslint-disable-next-line no-unused-vars
  LAWYER = 'LAWYER',
}

export enum PriceOptions {
  // eslint-disable-next-line no-unused-vars
  UP_TO_50 = 'UP_TO_50',
  // eslint-disable-next-line no-unused-vars
  FROM_50_TO_100 = 'FROM_50_TO_100',
  // eslint-disable-next-line no-unused-vars
  FROM_100_TO_150 = 'FROM_100_TO_150',
  // eslint-disable-next-line no-unused-vars
  MORE_THAN_150 = 'MORE_THAN_150',
}

export enum PublicSortByOptions {
  // eslint-disable-next-line no-unused-vars
  CHEAPEST_FIRST = 'CHEAPEST_FIRST',
  // eslint-disable-next-line no-unused-vars
  EXPENSIVE_FIRST = 'EXPENSIVE_FIRST',
  // eslint-disable-next-line no-unused-vars
  // HIGHEST_RATED = 'HIGHEST_RATED', TODO add when reviews will be used
}

export enum AccountActivationStatus {
  // eslint-disable-next-line no-unused-vars
  PENDING = 'PENDING',
  // eslint-disable-next-line no-unused-vars
  INACTIVE = 'INACTIVE',
  // eslint-disable-next-line no-unused-vars
  ACTIVE = 'ACTIVE',
}
