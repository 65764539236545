import * as actionTypes from './actionTypes';
import { CityActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { City } from '../../domain/City';

export type CityStateType = {
  citiesList: ListResults<City> | null;
  citiesListLoading: boolean;
  citiesListError: HttpError;
  citiesListUpdateNeeded: boolean;
  cityCreateLoading: boolean;
  cityCreateError: HttpError;
  cityCreateSuccess: boolean;
  createdCity: City | null;
  cityUpdateLoading: boolean;
  cityUpdateError: HttpError;
  cityUpdateSuccess: boolean;
  city: City | null;
  cityLoading: boolean;
  cityError: HttpError;
  cityDeleteLoading: boolean;
  cityDeleteError: HttpError;
  cityDeleteSuccess: boolean;
  allCities: City[];
  allCitiesLoading: boolean;
  allCitiesError: HttpError;
};

export type CityActionType = CityStateType & {
  type: CityActionTypes;
};

export const initialState: CityStateType = {
  citiesList: null,
  citiesListLoading: true,
  citiesListError: null,
  citiesListUpdateNeeded: false,
  cityCreateLoading: false,
  cityCreateError: null,
  cityCreateSuccess: false,
  createdCity: null,
  cityUpdateLoading: false,
  cityUpdateError: null,
  cityUpdateSuccess: false,
  city: null,
  cityLoading: false,
  cityError: null,
  cityDeleteLoading: false,
  cityDeleteError: null,
  cityDeleteSuccess: false,
  allCities: [],
  allCitiesLoading: false,
  allCitiesError: null,
};

const fetchCitiesListStart = (state: CityStateType): CityStateType => ({
  ...state,
  citiesListLoading: true,
});

const fetchCitiesListSuccess = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  citiesListLoading: false,
  citiesList: action.citiesList,
  citiesListUpdateNeeded: false,
});

const fetchCitiesListFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  citiesListLoading: false,
  citiesListError: action.citiesListError,
});

const fetchCityStart = (state: CityStateType): CityStateType => ({
  ...state,
  cityLoading: true,
});

const fetchCitySuccess = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityLoading: false,
  city: action.city,
});

const fetchCityFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityLoading: false,
  cityError: action.cityError,
});

const createCityStart = (state: CityStateType): CityStateType => ({
  ...state,
  cityCreateLoading: true,
});

const createCitySuccess = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityCreateLoading: false,
  cityCreateError: null,
  cityCreateSuccess: true,
  citiesListUpdateNeeded: true,
  createdCity: action.createdCity,
  citiesList: state.citiesList
    ? {
        total: state.citiesList.total + 1,
        result: [...state.citiesList.result, action.createdCity] as City[],
      }
    : null,
});

const createCityFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityCreateLoading: false,
  cityCreateError: action.cityCreateError,
});

const updateCityStart = (state: CityStateType): CityStateType => ({
  ...state,
  cityUpdateLoading: true,
});

const updateCitySuccess = (state: CityStateType): CityStateType => ({
  ...state,
  cityUpdateLoading: false,
  cityUpdateSuccess: true,
  citiesListUpdateNeeded: true,
});

const updateCityFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityUpdateLoading: false,
  cityUpdateError: action.cityUpdateError,
});

const deleteCityStart = (state: CityStateType): CityStateType => ({
  ...state,
  cityDeleteLoading: true,
});

const deleteCitySuccess = (state: CityStateType): CityStateType => ({
  ...state,
  cityDeleteLoading: false,
  cityDeleteSuccess: true,
  citiesListUpdateNeeded: true,
});

const deleteCityFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityDeleteLoading: false,
  cityDeleteError: action.cityDeleteError,
});

const resetCreatedCityStore = (state: CityStateType): CityStateType => ({
  ...state,
  cityCreateLoading: false,
  cityCreateError: null,
  cityCreateSuccess: false,
  createdCity: null,
});

const fetchAllCitiesStart = (state: CityStateType): CityStateType => ({
  ...state,
  allCitiesLoading: true,
});

const fetchAllCitiesSuccess = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  allCitiesLoading: false,
  allCities: action.allCities,
});

const fetchAllCitiesFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  allCitiesLoading: false,
  allCitiesError: action.allCitiesError,
});

const resetCityStore = (): CityStateType => ({
  ...initialState,
});

const completelyResetCityStore = (): CityStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CityActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CITIES_LIST_START:
      return fetchCitiesListStart(state);
    case actionTypes.FETCH_CITIES_LIST_SUCCESS:
      return fetchCitiesListSuccess(state, action);
    case actionTypes.FETCH_CITIES_LIST_FAIL:
      return fetchCitiesListFail(state, action);
    case actionTypes.FETCH_CITY_START:
      return fetchCityStart(state);
    case actionTypes.FETCH_CITY_SUCCESS:
      return fetchCitySuccess(state, action);
    case actionTypes.FETCH_CITY_FAIL:
      return fetchCityFail(state, action);
    case actionTypes.CREATE_CITY_START:
      return createCityStart(state);
    case actionTypes.CREATE_CITY_SUCCESS:
      return createCitySuccess(state, action);
    case actionTypes.CREATE_CITY_FAIL:
      return createCityFail(state, action);
    case actionTypes.UPDATE_CITY_START:
      return updateCityStart(state);
    case actionTypes.UPDATE_CITY_SUCCESS:
      return updateCitySuccess(state);
    case actionTypes.UPDATE_CITY_FAIL:
      return updateCityFail(state, action);
    case actionTypes.DELETE_CITY_START:
      return deleteCityStart(state);
    case actionTypes.DELETE_CITY_SUCCESS:
      return deleteCitySuccess(state);
    case actionTypes.DELETE_CITY_FAIL:
      return deleteCityFail(state, action);
    case actionTypes.FETCH_ALL_CITIES_START:
      return fetchAllCitiesStart(state);
    case actionTypes.FETCH_ALL_CITIES_SUCCESS:
      return fetchAllCitiesSuccess(state, action);
    case actionTypes.FETCH_ALL_CITIES_FAIL:
      return fetchAllCitiesFail(state, action);
    case actionTypes.RESET_CITY_STORE:
      return resetCityStore();
    case actionTypes.RESET_CREATED_CITY_STORE:
      return resetCreatedCityStore(state);
    case actionTypes.LOGOUT:
      return completelyResetCityStore();
    default:
      return state;
  }
};

export default reducer;
