import React from 'react';
import styles from './Footer.module.scss';
import FooterCopyright from './FooterCopyright/FooterCopyright';
import FooterContent from './FooterContent/FooterContent';

const Footer = () => {
  return (
    <div className={styles.footerBackground}>
      <div className={styles.footerContainer}>
        <FooterContent />
        <FooterCopyright />
      </div>
    </div>
  );
};

export default Footer;
