import React, { FormEvent, useState } from 'react';
import TextField from '../TextField/TextField';
import { InputAdornment } from '@mui/material';
import { routes } from '../../config/Router/routes';
import { useNavigate } from 'react-router-dom';
import Form from '../Form/Form';
import searchIcon from '../../assets/icons/search.svg';
import styles from './PublicSearchInput.module.scss';
import { ReactSVG } from 'react-svg';

export type SearchInputProps = {
  name: string;
  onSearch: (e: FormEvent) => void;
  placeholder: string;
  endAdornment?: boolean;
};

const PublicSearchInput = ({
  name,
  placeholder,
  endAdornment,
}: SearchInputProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const getAdornmentProperty = () => {
    if (endAdornment) {
      return {
        endAdornment: (
          <InputAdornment position="start">
            <ReactSVG onClick={(event) => onSubmit(event)} src={searchIcon} />
          </InputAdornment>
        ),
      };
    }
  };

  const onSubmit = (
    event: React.ChangeEvent<Element> | React.MouseEvent<Element> | undefined,
  ) => {
    event?.preventDefault();
    if (searchValue) {
      queryParams.set('search', searchValue);
      navigate({
        pathname: routes.publicLawyers.list,
        search: queryParams.toString(),
      });
      setSearchValue('');
    }
  };

  return (
    <Form emitEvent={true} onSubmit={(event) => onSubmit(event)} error={null}>
      <TextField
        onChange={(event) => setSearchValue(event.target.value)}
        placeholder={placeholder}
        name={name}
        type="text"
        value={searchValue}
        inputProps={getAdornmentProperty()}
        className={styles.publicSearchInput}
      />
    </Form>
  );
};

PublicSearchInput.defaultProps = {
  name: 'search',
  onSearch: () => {},
  onSearchChange: () => {},
  expandOnClick: true,
};

export default PublicSearchInput;
