import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Lawyer } from '../../domain/Lawyer';
import { LawyerActionTypes } from './actionTypes';
import { LawyerProfileStepTwoRequest } from './service';
import { PublicLawyer } from '../../domain/PublicLawyer';

export type LawyerStateType = {
  lawyersList: ListResults<Lawyer> | null;
  lawyersListLoading: boolean;
  lawyersListError: HttpError;
  publicLawyersList: ListResults<PublicLawyer> | null;
  publicLawyersListLoading: boolean;
  publicLawyersListError: HttpError;
  lawyersListUpdateNeeded: boolean;
  lawyerStatusUpdateLoading: boolean;
  lawyerStatusUpdateError: HttpError;
  lawyerStatusUpdateSuccess: boolean;
  profileInfoStepOneCategories: number[] | null;
  profileInfoStepTwo: LawyerProfileStepTwoRequest | null;
  createLawyerProfileInfoLoading: boolean;
  createLawyerProfileInfoError: HttpError | null;
  createLawyerProfileInfoSuccess: boolean;
  publicLawyer: PublicLawyer | null;
  publicLawyerLoading: boolean;
  publicLawyerSuccess: boolean;
  publicLawyerError: HttpError;
  similarLawyers: ListResults<PublicLawyer> | null;
  similarLawyersLoading: boolean;
  similarLawyersSuccess: boolean;
  similarLawyersError: HttpError;
  updateLawyerProfileInfoLoading: boolean;
  updateLawyerProfileInfoError: HttpError | null;
  updateLawyerProfileInfoSuccess: boolean;
  requestAccountActivationLoading: boolean;
  requestAccountActivationSuccess: boolean;
  requestAccountActivationError: HttpError | null;
};

export type LawyerActionType = LawyerStateType & {
  type: LawyerActionTypes;
};

export const initialState: LawyerStateType = {
  lawyersList: null,
  lawyersListLoading: true,
  lawyersListError: null,
  publicLawyersList: null,
  publicLawyersListLoading: false,
  publicLawyersListError: null,
  lawyersListUpdateNeeded: false,
  lawyerStatusUpdateLoading: false,
  lawyerStatusUpdateError: null,
  lawyerStatusUpdateSuccess: false,
  profileInfoStepOneCategories: null,
  profileInfoStepTwo: null,
  createLawyerProfileInfoLoading: false,
  createLawyerProfileInfoError: null,
  createLawyerProfileInfoSuccess: false,
  publicLawyer: null,
  publicLawyerLoading: false,
  publicLawyerSuccess: false,
  publicLawyerError: null,
  similarLawyers: null,
  similarLawyersLoading: false,
  similarLawyersSuccess: false,
  similarLawyersError: null,
  updateLawyerProfileInfoLoading: false,
  updateLawyerProfileInfoError: null,
  updateLawyerProfileInfoSuccess: false,
  requestAccountActivationLoading: false,
  requestAccountActivationSuccess: false,
  requestAccountActivationError: null,
};

const fetchLawyersListStart = (state: LawyerStateType): LawyerStateType => ({
  ...state,
  lawyersListLoading: true,
});

const fetchLawyersListSuccess = (
  state: LawyerStateType,
  action: LawyerActionType,
): LawyerStateType => ({
  ...state,
  lawyersListLoading: false,
  lawyersList: action.lawyersList,
  lawyersListUpdateNeeded: false,
});

const setProfileInfoStepOneCategories = (
  state: LawyerStateType,
  action: LawyerActionType,
): LawyerStateType => ({
  ...state,
  profileInfoStepOneCategories: action.profileInfoStepOneCategories,
});

const setProfileInfoStepTwo = (
  state: LawyerStateType,
  action: LawyerActionType,
): LawyerStateType => ({
  ...state,
  profileInfoStepTwo: action.profileInfoStepTwo,
});

const fetchLawyersListFail = (
  state: LawyerStateType,
  action: LawyerActionType,
): LawyerStateType => ({
  ...state,
  lawyersListLoading: false,
  lawyersListError: action.lawyersListError,
});

const updateLawyerStatusStart = (state: LawyerStateType): LawyerStateType => ({
  ...state,
  lawyerStatusUpdateLoading: true,
});

const updateLawyerStatusSuccess = (
  state: LawyerStateType,
): LawyerStateType => ({
  ...state,
  lawyerStatusUpdateLoading: false,
  lawyerStatusUpdateError: null,
  lawyerStatusUpdateSuccess: true,
  lawyersListUpdateNeeded: true,
});

const updateLawyerStatusFail = (
  state: LawyerStateType,
  action: LawyerActionType,
): LawyerStateType => ({
  ...state,
  lawyerStatusUpdateLoading: false,
  lawyerStatusUpdateError: action.lawyerStatusUpdateError,
});

const createLawyerProfileInfoStart = (
  state: LawyerStateType,
): LawyerStateType => ({
  ...state,
  createLawyerProfileInfoLoading: true,
});

const createLawyerProfileInfoSuccess = (
  state: LawyerStateType,
): LawyerStateType => ({
  ...state,
  createLawyerProfileInfoLoading: false,
  createLawyerProfileInfoError: null,
  createLawyerProfileInfoSuccess: true,
});

const createLawyerProfileInfoFail = (
  state: LawyerStateType,
  action: LawyerActionType,
): LawyerStateType => ({
  ...state,
  createLawyerProfileInfoLoading: false,
  createLawyerProfileInfoError: action.createLawyerProfileInfoError,
});

const fetchPublicLawyerStart = (state: LawyerStateType): LawyerStateType => ({
  ...state,
  publicLawyerLoading: true,
});

const fetchPublicLawyerSuccess = (
  state: LawyerStateType,
  action: LawyerActionType,
): LawyerStateType => ({
  ...state,
  publicLawyerLoading: false,
  publicLawyerSuccess: true,
  publicLawyer: action.publicLawyer,
});

const fetchPublicLawyerFail = (
  state: LawyerStateType,
  action: LawyerActionType,
): LawyerStateType => ({
  ...state,
  publicLawyerLoading: false,
  publicLawyerError: action.publicLawyerError,
});

const fetchSimilarLawyersStart = (state: LawyerStateType): LawyerStateType => ({
  ...state,
  similarLawyersLoading: true,
});

const fetchSimilarLawyersSuccess = (
  state: LawyerStateType,
  action: LawyerActionType,
): LawyerStateType => ({
  ...state,
  similarLawyersLoading: false,
  similarLawyersSuccess: true,
  similarLawyers: action.similarLawyers,
});

const fetchSimilarLawyersFail = (
  state: LawyerStateType,
  action: LawyerActionType,
): LawyerStateType => ({
  ...state,
  similarLawyersLoading: false,
  similarLawyersError: action.similarLawyersError,
});

const updateLawyerProfileInfoStart = (
  state: LawyerStateType,
): LawyerStateType => ({
  ...state,
  updateLawyerProfileInfoLoading: true,
});

const updateLawyerProfileInfoSuccess = (
  state: LawyerStateType,
): LawyerStateType => ({
  ...state,
  updateLawyerProfileInfoLoading: false,
  updateLawyerProfileInfoError: null,
  updateLawyerProfileInfoSuccess: true,
});

const updateLawyerProfileInfoFail = (
  state: LawyerStateType,
  action: LawyerActionType,
): LawyerStateType => ({
  ...state,
  updateLawyerProfileInfoLoading: false,
  updateLawyerProfileInfoError: action.updateLawyerProfileInfoError,
});

const resetLawyerProfileInfoStore = (
  state: LawyerStateType,
): LawyerStateType => ({
  ...state,
  createLawyerProfileInfoLoading: false,
  createLawyerProfileInfoError: null,
  createLawyerProfileInfoSuccess: false,
  updateLawyerProfileInfoLoading: false,
  updateLawyerProfileInfoError: null,
  updateLawyerProfileInfoSuccess: false,
});

const fetchPublicLawyersListStart = (
  state: LawyerStateType,
): LawyerStateType => ({
  ...state,
  publicLawyersListLoading: true,
});

const fetchPublicLawyersListSuccess = (
  state: LawyerStateType,
  action: LawyerActionType,
): LawyerStateType => ({
  ...state,
  publicLawyersListLoading: false,
  publicLawyersList: action.publicLawyersList,
});

const fetchPublicLawyersListFail = (
  state: LawyerStateType,
  action: LawyerActionType,
): LawyerStateType => ({
  ...state,
  publicLawyersListLoading: false,
  publicLawyersListError: action.lawyersListError,
});

const requestAccountActivationStart = (
  state: LawyerStateType,
): LawyerStateType => ({
  ...state,
  requestAccountActivationLoading: true,
});

const requestAccountActivationSuccess = (
  state: LawyerStateType,
): LawyerStateType => ({
  ...state,
  requestAccountActivationLoading: false,
  requestAccountActivationSuccess: true,
});

const requestAccountActivationFail = (
  state: LawyerStateType,
  action: LawyerActionType,
): LawyerStateType => ({
  ...state,
  requestAccountActivationLoading: false,
  requestAccountActivationError: action.requestAccountActivationError,
});

const resetPublicLawyerStore = (state: LawyerStateType): LawyerStateType => ({
  ...state,
  publicLawyerLoading: false,
  publicLawyerSuccess: false,
  publicLawyerError: null,
});

const resetSimilarLawyersStore = (state: LawyerStateType): LawyerStateType => ({
  ...state,
  similarLawyersLoading: false,
  similarLawyersSuccess: false,
  similarLawyersError: null,
});

const resetLawyersStore = (): LawyerStateType => ({
  ...initialState,
});

const logout = (state: LawyerStateType): LawyerStateType => ({
  ...initialState,
  publicLawyersList: state.publicLawyersList,
  publicLawyer: state.publicLawyer,
  similarLawyers: state.similarLawyers,
});

const reducer = (state = initialState, action: LawyerActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_LAWYERS_LIST_START:
      return fetchLawyersListStart(state);
    case actionTypes.FETCH_LAWYERS_LIST_SUCCESS:
      return fetchLawyersListSuccess(state, action);
    case actionTypes.FETCH_LAWYERS_LIST_FAIL:
      return fetchLawyersListFail(state, action);
    case actionTypes.FETCH_PUBLIC_LAWYER_START:
      return fetchPublicLawyerStart(state);
    case actionTypes.FETCH_PUBLIC_LAWYER_SUCCESS:
      return fetchPublicLawyerSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_LAWYER_FAIL:
      return fetchPublicLawyerFail(state, action);
    case actionTypes.FETCH_SIMILAR_LAWYERS_START:
      return fetchSimilarLawyersStart(state);
    case actionTypes.FETCH_SIMILAR_LAWYERS_SUCCESS:
      return fetchSimilarLawyersSuccess(state, action);
    case actionTypes.FETCH_SIMILAR_LAWYERS_FAIL:
      return fetchSimilarLawyersFail(state, action);
    case actionTypes.UPDATE_LAWYER_STATUS_START:
      return updateLawyerStatusStart(state);
    case actionTypes.UPDATE_LAWYER_STATUS_SUCCESS:
      return updateLawyerStatusSuccess(state);
    case actionTypes.UPDATE_LAWYER_STATUS_FAIL:
      return updateLawyerStatusFail(state, action);
    case actionTypes.CREATE_LAWYER_PROFILE_INFO_START:
      return createLawyerProfileInfoStart(state);
    case actionTypes.CREATE_LAWYER_PROFILE_INFO_SUCCESS:
      return createLawyerProfileInfoSuccess(state);
    case actionTypes.CREATE_LAWYER_PROFILE_INFO_FAIL:
      return createLawyerProfileInfoFail(state, action);
    case actionTypes.UPDATE_LAWYER_PROFILE_INFO_START:
      return updateLawyerProfileInfoStart(state);
    case actionTypes.UPDATE_LAWYER_PROFILE_INFO_SUCCESS:
      return updateLawyerProfileInfoSuccess(state);
    case actionTypes.UPDATE_LAWYER_PROFILE_INFO_FAIL:
      return updateLawyerProfileInfoFail(state, action);
    case actionTypes.SET_PROFILE_INFO_STEP_ONE_CATEGORIES:
      return setProfileInfoStepOneCategories(state, action);
    case actionTypes.SET_PROFILE_INFO_STEP_TWO:
      return setProfileInfoStepTwo(state, action);
    case actionTypes.RESET_LAWYER_PROFILE_INFO_STORE:
      return resetLawyerProfileInfoStore(state);
    case actionTypes.FETCH_PUBLIC_LAWYERS_LIST_START:
      return fetchPublicLawyersListStart(state);
    case actionTypes.FETCH_PUBLIC_LAWYERS_LIST_SUCCESS:
      return fetchPublicLawyersListSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_LAWYERS_LIST_FAIL:
      return fetchPublicLawyersListFail(state, action);
    case actionTypes.REQUEST_ACCOUNT_ACTIVATION_START:
      return requestAccountActivationStart(state);
    case actionTypes.REQUEST_ACCOUNT_ACTIVATION_SUCCESS:
      return requestAccountActivationSuccess(state);
    case actionTypes.REQUEST_ACCOUNT_ACTIVATION_FAIL:
      return requestAccountActivationFail(state, action);
    case actionTypes.RESET_PUBLIC_LAWYER_STORE:
      return resetPublicLawyerStore(state);
    case actionTypes.RESET_SIMILAR_LAWYERS_STORE:
      return resetSimilarLawyersStore(state);
    case actionTypes.RESET_LAWYERS_STORE:
      return resetLawyersStore();
    case actionTypes.LOGOUT:
      return logout(state);
    default:
      return state;
  }
};

export default reducer;
