import React from 'react';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';

type Props = {
  isMobileMenuOpen: boolean;
  clickHandler: () => void;
};

const HamburgerButton = ({ isMobileMenuOpen, clickHandler }: Props) => {
  return (
    <IconButton onClick={clickHandler}>
      <FontAwesomeIcon
        icon={isMobileMenuOpen ? faTimes : faBars}
        fixedWidth
        size="sm"
        color="black"
      />
    </IconButton>
  );
};

export default HamburgerButton;
