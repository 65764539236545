import React from 'react';
import Modal from '../../../../common/Modal/Modal';
import styles from './PlanConfirmationModal.module.scss';
import { useIntl } from 'react-intl';
import { translate } from '../../../../utility/messageTranslator/translate';
import Button from '../../../../common/Button/Button';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  setPlanMonthsSelected: (state: string) => void;
  planMonthsSelected: string;
  setIsFreeSelected: (state: boolean) => void;
  isFreeSelected: boolean;
};

const PlanConfirmationModal = ({
  isOpen,
  onClose,
  setPlanMonthsSelected,
  planMonthsSelected,
  setIsFreeSelected,
  isFreeSelected,
}: Props) => {
  const intl = useIntl();

  const handleClose = () => {
    setPlanMonthsSelected('');
    setIsFreeSelected(false);
    onClose();
  };

  const getDaysInfoLabel = () => {
    if (isFreeSelected) {
      return 'PLAN_FREE_CONFIRMATION_MODAL.INFO_DATE';
    }

    return 'PLAN_CONFIRMATION_MODAL.INFO_DATE';
  };

  return (
    <Modal
      className={styles.modalContainer}
      onClose={() => handleClose()}
      isOpen={isOpen}
      excludeCloseBtn
    >
      <div className={styles.planConfirmationContainer}>
        <div className={styles.title}>
          {translate(intl, 'PLAN_CONFIRMATION_MODAL.TITLE')}
        </div>
        <div
          className={styles.daysInfo}
          dangerouslySetInnerHTML={{
            __html: translate(intl, `${getDaysInfoLabel()}`).replace(
              ':value',
              planMonthsSelected,
            ),
          }}
        />
        <div className={styles.payDetails}>
          {isFreeSelected ? (
            translate(intl, 'PLAN_FREE_CONFIRMATION_MODAL.INFO')
          ) : (
            <>
              {translate(intl, 'PLAN_CONFIRMATION_MODAL.INFO')}
              {translate(intl, 'PLAN_CONFIRMATION_MODAL.INFO_PAY')}
            </>
          )}
        </div>
        <Button color="primary" onClick={() => onClose()}>
          {translate(intl, 'PLAN_CONFIRMATION_MODAL.CLOSE')}
        </Button>
      </div>
    </Modal>
  );
};

export default PlanConfirmationModal;
