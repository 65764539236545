import React, { ChangeEvent, ReactNode, useEffect, useRef } from 'react';
import { HttpError } from '../../config/Axios/axios-instance';

type Props = {
  children: ReactNode;
  onSubmit: (event?: ChangeEvent) => void;
  error: HttpError;
  className?: string;
  scrollIntoView?: boolean;
  emitEvent?: boolean;
};

export const Form = ({
  children,
  onSubmit,
  error,
  className,
  emitEvent = false,
  scrollIntoView = true,
}: Props) => {
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (error && scrollIntoView) {
      formRef?.current?.scrollIntoView();
      window.scrollBy(0, -60);
    }
  }, [error]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={
        emitEvent
          ? (event) => onSubmit(event as ChangeEvent<HTMLFormElement>)
          : () => onSubmit()
      }
      ref={formRef}
      className={className}
    >
      {children}
    </form>
  );
};

export default Form;
