import React, { ReactNode } from 'react';
import { routes } from '../../../config/Router/routes';
import PublicNavigation from '../../PublicNavigation/PublicNavigation';
import Footer from '../../../common/Footer/Footer';

type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
};

const IGNORED_ROUTES = [
  routes.login,
  routes.resetPassword.replace('/:code', ''),
  routes.profile.profile,
  routes.createEnquiry,
  routes.publicEnquiry.publicEnquiriesPage,
];

const PublicLayout = ({ isAuthenticated, children }: Props) => {
  const isRouteIgnored = IGNORED_ROUTES.find((ignoredRoute) =>
    location.pathname.includes(ignoredRoute),
  );

  if (isRouteIgnored) {
    return (
      <>
        <PublicNavigation isAuthenticated={isAuthenticated} />
        {children}
      </>
    );
  }

  return (
    <>
      <PublicNavigation isAuthenticated={isAuthenticated} />
      {children}
      <Footer />
    </>
  );
};

export default PublicLayout;
