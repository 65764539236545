import * as actionTypes from './actionTypes';
import { EnquiryActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Enquiry } from '../../domain/Enquiry';
import { ListResults } from '../../common/List/List';

export type EnquiryStateType = {
  enquiriesList: ListResults<Enquiry> | null;
  enquiriesListLoading: boolean;
  enquiriesListError: HttpError;
  enquiriesListUpdateNeeded: boolean;
  publicEnquiryCreateLoading: boolean;
  publicEnquiryCreateError: HttpError | null;
  publicEnquiryCreateSuccess: boolean;
  publicEnquiriesLoading: boolean;
  publicEnquiriesError: HttpError | null;
  publicEnquiries: Enquiry[] | null;
  publicEnquiryLoading: boolean;
  publicEnquiryError: HttpError | null;
  publicEnquiry: Enquiry | null;
  enquiryStatusChangeLoading: boolean;
  enquiryStatusChangeError: HttpError | null;
  enquiryStatusChangeSuccess: boolean;
};

export type EnquiryActionType = EnquiryStateType & {
  type: EnquiryActionTypes;
};

export const initialState: EnquiryStateType = {
  enquiriesList: null,
  enquiriesListLoading: true,
  enquiriesListError: null,
  enquiriesListUpdateNeeded: false,
  publicEnquiryCreateLoading: false,
  publicEnquiryCreateError: null,
  publicEnquiryCreateSuccess: false,
  publicEnquiriesLoading: false,
  publicEnquiriesError: null,
  publicEnquiries: null,
  publicEnquiryLoading: false,
  publicEnquiryError: null,
  publicEnquiry: null,
  enquiryStatusChangeLoading: false,
  enquiryStatusChangeError: null,
  enquiryStatusChangeSuccess: false,
};

const fetchEnquiriesListStart = (
  state: EnquiryStateType,
): EnquiryStateType => ({
  ...state,
  enquiriesListLoading: true,
});

const fetchEnquiriesListSuccess = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  enquiriesListLoading: false,
  enquiriesList: action.enquiriesList,
  enquiriesListUpdateNeeded: false,
});

const fetchEnquiriesListFail = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  enquiriesListLoading: false,
  enquiriesListError: action.enquiriesListError,
});

const createPublicEnquiryStart = (
  state: EnquiryStateType,
): EnquiryStateType => ({
  ...state,
  publicEnquiryCreateLoading: true,
});

const createPublicEnquirySuccess = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  publicEnquiryCreateLoading: false,
  publicEnquiryCreateError: null,
  publicEnquiryCreateSuccess: true,
});

const createPublicEnquiryFail = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  publicEnquiryCreateLoading: false,
  publicEnquiryCreateError: action.publicEnquiryCreateError,
});

const fetchPublicEnquiriesStart = (
  state: EnquiryStateType,
): EnquiryStateType => ({
  ...state,
  publicEnquiriesLoading: true,
});

const fetchPublicEnquiriesSuccess = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  publicEnquiriesLoading: false,
  publicEnquiriesError: null,
  publicEnquiries: action.publicEnquiries,
});

const fetchPublicEnquiriesFail = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  publicEnquiriesLoading: false,
  publicEnquiriesError: action.publicEnquiriesError,
});

const fetchPublicEnquiryStart = (
  state: EnquiryStateType,
): EnquiryStateType => ({
  ...state,
  publicEnquiryLoading: true,
});

const fetchPublicEnquirySuccess = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  publicEnquiryLoading: false,
  publicEnquiryError: null,
  publicEnquiry: action.publicEnquiry,
});

const fetchPublicEnquiryFail = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  publicEnquiryLoading: false,
  publicEnquiryError: action.publicEnquiryError,
});

const changeEnquiryStatusStart = (
  state: EnquiryStateType,
): EnquiryStateType => ({
  ...state,
  enquiryStatusChangeLoading: true,
});

const changeEnquiryStatusSuccess = (
  state: EnquiryStateType,
): EnquiryStateType => ({
  ...state,
  enquiryStatusChangeLoading: false,
  enquiryStatusChangeError: null,
  enquiryStatusChangeSuccess: true,
  enquiriesListUpdateNeeded: true,
});

const changeEnquiryStatusFail = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  enquiryStatusChangeLoading: false,
  enquiryStatusChangeError: action.enquiryStatusChangeError,
});

const resetPublicEnquiry = (state: EnquiryStateType): EnquiryStateType => ({
  ...state,
  publicEnquiryLoading: false,
  publicEnquiryError: null,
  publicEnquiry: null,
});

const resetPublicEnquiries = (state: EnquiryStateType): EnquiryStateType => ({
  ...state,
  publicEnquiriesLoading: false,
  publicEnquiriesError: null,
  publicEnquiries: null,
});

const resetEnquiryStore = (): EnquiryStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: EnquiryActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PUBLIC_ENQUIRY_START:
      return fetchPublicEnquiryStart(state);
    case actionTypes.FETCH_PUBLIC_ENQUIRY_SUCCESS:
      return fetchPublicEnquirySuccess(state, action);
    case actionTypes.FETCH_PUBLIC_ENQUIRY_FAIL:
      return fetchPublicEnquiryFail(state, action);
    case actionTypes.FETCH_ENQUIRIES_LIST_START:
      return fetchEnquiriesListStart(state);
    case actionTypes.FETCH_ENQUIRIES_LIST_SUCCESS:
      return fetchEnquiriesListSuccess(state, action);
    case actionTypes.FETCH_ENQUIRIES_LIST_FAIL:
      return fetchEnquiriesListFail(state, action);
    case actionTypes.CREATE_PUBLIC_ENQUIRY_START:
      return createPublicEnquiryStart(state);
    case actionTypes.CREATE_PUBLIC_ENQUIRY_SUCCESS:
      return createPublicEnquirySuccess(state, action);
    case actionTypes.CREATE_PUBLIC_ENQUIRY_FAIL:
      return createPublicEnquiryFail(state, action);
    case actionTypes.FETCH_PUBLIC_ENQUIRIES_START:
      return fetchPublicEnquiriesStart(state);
    case actionTypes.FETCH_PUBLIC_ENQUIRIES_SUCCESS:
      return fetchPublicEnquiriesSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_ENQUIRIES_FAIL:
      return fetchPublicEnquiriesFail(state, action);
    case actionTypes.CHANGE_ENQUIRY_STATUS_START:
      return changeEnquiryStatusStart(state);
    case actionTypes.CHANGE_ENQUIRY_STATUS_SUCCESS:
      return changeEnquiryStatusSuccess(state);
    case actionTypes.CHANGE_ENQUIRY_STATUS_FAIL:
      return changeEnquiryStatusFail(state, action);
    case actionTypes.RESET_PUBLIC_ENQUIRY:
      return resetPublicEnquiry(state);
    case actionTypes.RESET_PUBLIC_ENQUIRIES:
      return resetPublicEnquiries(state);
    case actionTypes.RESET_ENQUIRY_STORE:
      return resetEnquiryStore();
    case actionTypes.LOGOUT:
      return resetEnquiryStore();
    default:
      return state;
  }
};

export default reducer;
