import React, { ReactNode, useState, useEffect } from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/Sidebar';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import {
  faUsers,
  faGlobe,
  faLanguage,
  faFolderTree,
  faCity,
  faEnvelopeOpenText,
  faSuitcase,
} from '@fortawesome/free-solid-svg-icons';
import { routes } from '../../config/Router/routes';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import PublicLayout from './PublicLayout/PublicLayout';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { setPostLoginUrl } from '../../store/auth/actions';

export type Props = {
  children: ReactNode;
  currentUser: User | null;
  isAuthenticated: boolean;
  onSetPostLoginUrl: () => void;
  postLoginUrl: string | null;
};

export type NavigationItem = {
  label: string;
  to: string;
  icon?: IconProp;
};

export type NavigationGroup = {
  label: string;
  items: NavigationItem[];
};

const MOBILE_BREAK_POINT = 900;

const Layout = ({
  children,
  currentUser,
  isAuthenticated,
  onSetPostLoginUrl,
  postLoginUrl,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const intl = useIntl();
  const navigate = useNavigate();

  const { width } = useWindowSize();

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.key]);

  useEffect(() => {
    if (isAuthenticated && postLoginUrl) {
      navigate(`${postLoginUrl}?loggedIn=true`);
      onSetPostLoginUrl();
    }
  }, [isAuthenticated]);

  const SIDEBAR_ITEMS = [
    {
      label: translate(intl, 'SIDEBAR.MANAGEMENT_SETTINGS'),
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_CATEGORIES'),
          to: routes.categories.list,
          icon: faFolderTree as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_CITIES'),
          to: routes.cities.list,
          icon: faCity as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ENQUIRIES'),
          to: routes.enquiries.list,
          icon: faEnvelopeOpenText as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_LAWYERS'),
          to: routes.lawyers.list,
          icon: faSuitcase as IconProp,
          roles: [Roles.ADMIN],
        },
      ],
    },
    {
      label: translate(intl, 'NAVIGATION.GROUP_GLOBAL'),
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_USERS'),
          to: routes.users.list,
          icon: faUsers as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_LANGUAGES'),
          to: routes.languages,
          icon: faLanguage as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TRANSLATIONS'),
          to: routes.translations,
          icon: faGlobe as IconProp,
          roles: [Roles.ADMIN],
        },
      ],
    },
  ];

  useEffect(() => {
    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  const getNavigationGroups = () => {
    if (currentUser?.role === Roles.OWNER) {
      return [];
    }

    const sidebarItems = SIDEBAR_ITEMS.map((sidebarItemGroup) => ({
      label: sidebarItemGroup.label,
      items: sidebarItemGroup.items.filter((sidebarItem) => {
        return (
          !!currentUser?.role && sidebarItem.roles.includes(currentUser.role)
        );
      }),
    }));

    return sidebarItems;
  };

  useEffect(() => {
    // @ts-ignore
    window?.dataLayer?.push({
      event: 'pageview',
    });
  }, [location.key]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (
      isAuthenticated &&
      !currentUser?.profile?.isFinished &&
      currentUser?.role !== Roles.ADMIN &&
      location.pathname !== routes.profile.edit &&
      location.pathname !== routes.privacyPolicy
    ) {
      return navigate(routes.profile.edit);
    }
  }, [isAuthenticated, location.key]);

  if (!location.pathname.includes(routes.admin)) {
    return (
      <PublicLayout isAuthenticated={isAuthenticated}>{children}</PublicLayout>
    );
  }

  return (
    <>
      <div className={styles.container}>
        {width && width >= MOBILE_BREAK_POINT && (
          <Sidebar navigationGroups={getNavigationGroups()} />
        )}
        <div
          className={cx(styles.rightSection, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <Navigation
            onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
            isMobileMenuOpen={isMobileMenuOpen}
          />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {getNavigationGroups().map((navigationGroup) => (
            <div className={styles.navigationGroup} key={navigationGroup.label}>
              <div className={styles.groupName}>{navigationGroup.label}</div>
              {navigationGroup.items.map((item) => (
                <NavLink
                  key={item.label}
                  to={item.to}
                  className={({ isActive }) =>
                    cx(styles.navigationItem, {
                      [styles.activeSubItem]: isActive,
                    })
                  }
                >
                  {item.label}
                </NavLink>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
  isAuthenticated: state.auth.isAuthenticated,
  postLoginUrl: state.auth.postLoginUrl,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSetPostLoginUrl: () => dispatch(setPostLoginUrl(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
