import React from 'react';
import styles from './GoBackButton.module.scss';
import goBackArrowIcon from '../../../assets/icons/go-back-arrow-icon.svg';
import { ReactSVG } from 'react-svg';
import { translate } from '../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import cx from 'classnames';

type Props = {
  onClick: () => void;
  className?: string;
};

const GoBackButton = ({ onClick, className }: Props) => {
  const intl = useIntl();

  return (
    <div className={cx(styles.goBackBtnContainer, className)} onClick={onClick}>
      <ReactSVG src={goBackArrowIcon} />
      <span>{translate(intl, 'PASSWORD_REMIND_FORM.GO_BACK_BTN')}</span>
    </div>
  );
};

export default GoBackButton;
