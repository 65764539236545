export const routes = {
  homepage: '/',
  publicEnquiry: {
    publicEnquiriesPage: '/enquiries',
    singlePublicEnquiryPage: '/enquiries/:id',
  },
  createEnquiry: '/create-enquiry',
  publicLawyers: {
    list: '/lawyers',
    lawyer: '/lawyers/:id',
  },
  privacyPolicy: '/privacy-policy',
  login: '/login',
  register: '/register',
  registrationConfirmation: '/registration-confirmation/:code',
  remind: '/remind-password',
  resetPassword: '/password-reset/:code',
  passwordChangePage: '/change-password',
  profile: {
    profile: '/profile',
    edit: '/profile/edit',
  },
  dynamicPage: '/:locale/:slug',
  admin: '/admin',
  users: {
    list: '/admin/users',
    create: '/admin/users/new',
    edit: '/admin/users/:id',
  },
  lawyers: {
    list: '/admin/lawyers',
  },
  categories: {
    list: '/admin/categories',
  },
  cities: {
    list: '/admin/cities',
  },
  translations: '/admin/translations',
  comingSoon: '/coming-soon',
  languages: '/admin/languages',
  enquiries: {
    list: '/admin/enquiries',
  },
  rules: '/rules',
};
