import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { routes } from '../../..//config/Router/routes';
import { StoreState } from '../../..//config/StoreProvider/StoreProvider';
import { getLocaleUrl } from '../../../utility/url/urlHelper';
import { Locale } from '../../../domain/Translation';

type Props = {
  logoSrc: string;
  selectedLocale: Locale;
  className: string;
};

const LogoLink = ({ logoSrc, selectedLocale, className }: Props) => {
  return (
    <Link
      to={getLocaleUrl(routes.homepage, selectedLocale)}
      className={className}
    >
      <ReactSVG src={logoSrc} />
    </Link>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
});

export default connect(mapStateToProps)(LogoLink);
