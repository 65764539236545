import React from 'react';
import styles from './FooterContent.module.scss';
import facebookLogo from '../../../assets/footer/facebook.svg';
import linkedinLogo from '../../../assets/footer/linkedin.svg';
import { useIntl } from 'react-intl';
import { translate } from '../../../utility/messageTranslator/translate';
import { NavLink } from 'react-router-dom';
import { routes } from '../../../config/Router/routes';
import LogoLink from '../../../common/PublicNavigation/LogoLink/LogoLink';
import logo from '../../../assets/logo.svg';

const FooterContent = () => {
  const intl = useIntl();
  return (
    <div className={styles.footerLinks}>
      <div className={styles.footerLinksColumn}>
        <ul className={styles.footerLinksList}>
          <li className={styles.footerLinkItem}>
            <LogoLink logoSrc={logo} className={styles.footerLogo} />
          </li>
          <li className={styles.footerLinkItem}>
            {translate(intl, 'FOOTER.COMPANY_NAME')}
          </li>
          <li className={styles.footerLinkItem}>
            {translate(intl, 'FOOTER.COMPANY_CODE')}
          </li>
        </ul>
      </div>
      <div className={styles.footerLinksColumn}>
        <ul className={styles.footerLinksList}>
          <li className={styles.footerLinksTitle}>
            {translate(intl, 'FOOTER.CONTACTS')}
          </li>
          <li className={styles.footerLinkItem}>
            <a href={`mailto:${translate(intl, 'FOOTER.COMPANY_EMAIL')}"`}>
              {translate(intl, 'FOOTER.COMPANY_EMAIL')}
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.footerLinksColumn}>
        <ul className={styles.footerLinksList}>
          <li className={styles.footerLinksTitle}>
            {translate(intl, 'FOOTER.USEFUL_INFORMATION')}
          </li>
          <li className={styles.footerLinkItem}>
            <NavLink to={routes.privacyPolicy}>
              {translate(intl, 'FOOTER.PRIVACY_POLICY')}
            </NavLink>
          </li>
          <li className={styles.footerLinkItem}>
            <NavLink to={routes.rules}>
              {translate(intl, 'FOOTER.RULES')}
            </NavLink>
          </li>
        </ul>
      </div>
      <div className={styles.footerLinksColumn}>
        <ul className={styles.footerLinksList}>
          <li className={styles.footerLinksTitle}>
            {translate(intl, 'FOOTER.FOLLOW_US')}
          </li>
          <li className={styles.footerLinkItem}>
            <a
              href={translate(intl, 'FOOTER.SOCIAL_LINKEDIN_URL')}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.footerSocialLink}
            >
              <img
                src={linkedinLogo}
                alt={`${translate(intl, 'FOOTER.SOCIAL_LINKEDIN')} logo`}
              />
              <div> {translate(intl, 'FOOTER.SOCIAL_LINKEDIN')}</div>
            </a>
          </li>
          <li className={styles.footerLinkItem}>
            <a
              href={translate(intl, 'FOOTER.SOCIAL_FACEBOOK_URL')}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.footerSocialLink}
            >
              <img
                src={facebookLogo}
                alt={`${translate(intl, 'FOOTER.SOCIAL_FACEBOOK')} logo`}
              />
              <div> {translate(intl, 'FOOTER.SOCIAL_FACEBOOK')}</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterContent;
