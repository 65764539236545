import React, { useEffect, useState } from 'react';
import styles from './PublicNavigation.module.scss';
import logo from '../../assets/logo.svg';
import LogoLink from './LogoLink/LogoLink';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import HamburgerButton from './HamburgerButton/HamburgerButton';
import { useLocation } from 'react-router-dom';
import Button from '../Button/Button';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import ProfileButton from '../ProfileButton/ProfileButton';
import { routes } from '../../config/Router/routes';
import { useNavigate } from 'react-router-dom';
import CommonLinks from './CommonLinks/CommonLinks';
import PublicSearchInput from '../PublicSearchInput/PublicSearchInput';
import PlanModal from '../../component/Public/PlanModals/PlanModal/PlanModal';
import PlanConfirmationModal from '../../component/Public/PlanModals/PlanConfirmationModal/PlanConfirmationModal';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { Roles } from '../../domain/Role';
import { User } from '../../domain/User';
import { AccountActivationStatus } from '../../domain/Lawyer';
import moment from 'moment';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as userService from '../../store/user/service';

export const MOBILE_BREAK_POINT = 1200;

export type Props = {
  isAuthenticated: boolean;
  currentUser: User | null;
  onFetchCurrentUser: () => void;
};

const PublicNavigation = ({
  isAuthenticated,
  currentUser,
  onFetchCurrentUser,
}: Props) => {
  const { width } = useWindowSize();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const intl = useIntl();
  const navigate = useNavigate();
  const [isHomePage, setIsHomePage] = useState<boolean>(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState<boolean>(false);
  const [isPlanConfirmationModalOpen, setIsPlanConfirmationModalOpen] =
    useState<boolean>(false);
  const [planMonthsSelected, setPlanMonthsSelected] = useState<string>('');
  const [isFreeSelected, setIsFreeSelected] = useState<boolean>(false);

  useEffect(() => {
    setIsHomePage(location.pathname === routes.homepage);
  }, [location.key]);

  useEffect(() => {
    if (width && width > MOBILE_BREAK_POINT) {
      setIsMobileMenuOpen(false);
    }
  }, [width]);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.key]);

  const getAccountStatus = () => {
    if (!currentUser || !currentUser.lawyer) {
      return;
    }

    switch (currentUser.lawyer.accountStatus) {
      case AccountActivationStatus.ACTIVE:
        return (
          <div
            className={styles.activeAccountStatus}
            dangerouslySetInnerHTML={{
              __html: translate(intl, 'ACCOUNT_STATUS.ACTIVE').replace(
                ':value',
                moment(currentUser.lawyer.activeAccountEnd).format(
                  'YYYY-MM-DD',
                ),
              ),
            }}
          />
        );
      case AccountActivationStatus.PENDING:
        return (
          <div
            className={styles.accountStatus}
            dangerouslySetInnerHTML={{
              __html: translate(intl, 'ACCOUNT_STATUS.PENDING'),
            }}
          />
        );
      case AccountActivationStatus.INACTIVE:
        return (
          <Button color="primary" onClick={() => setIsPlanModalOpen(true)}>
            {translate(intl, 'PUBLIC_NAVIGATION.ACTIVATE_ACCOUNT')}
          </Button>
        );
    }
  };

  const getMenuButtons = () => {
    if (isAuthenticated) {
      return (
        <>
          {!isHomePage && (
            <div className={styles.searchbarContainer}>
              <PublicSearchInput
                placeholder={translate(
                  intl,
                  'HOMEPAGE.LEGAL_FIELDS_SECTION_SEARCH',
                )}
                endAdornment
              />
            </div>
          )}
          <div className={styles.buttonContainer}>
            <Button
              color="secondary"
              onClick={() => navigate(routes.publicLawyers.list)}
            >
              {translate(intl, 'PUBLIC_NAVIGATION.BUTTON_LAWYERS_LIST')}
            </Button>
            {currentUser &&
              currentUser.role === Roles.OWNER &&
              getAccountStatus()}
            <ProfileButton />
          </div>
        </>
      );
    }

    return (
      <>
        {!isHomePage && (
          <div className={styles.searchbarContainer}>
            <PublicSearchInput
              placeholder={translate(
                intl,
                'HOMEPAGE.LEGAL_FIELDS_SECTION_SEARCH',
              )}
              endAdornment
            />
          </div>
        )}
        <div className={styles.buttonContainer}>
          <Button
            color="secondary"
            onClick={() => navigate(routes.publicLawyers.list)}
          >
            {translate(intl, 'PUBLIC_NAVIGATION.BUTTON_LAWYERS_LIST')}
          </Button>
          <Button color="secondary" onClick={() => navigate(routes.login)}>
            {translate(intl, 'PUBLIC_NAVIGATION.BUTTON_LAWYERS')}
          </Button>
          <Button onClick={() => navigate(routes.createEnquiry)}>
            {translate(intl, 'PUBLIC_NAVIGATION.FILL_REQUEST')}
          </Button>
        </div>
      </>
    );
  };

  const handlePlanConfirmationModalClose = () => {
    setIsPlanConfirmationModalOpen(false);
    onFetchCurrentUser();
  };

  return (
    <header className={styles.navigationContainer}>
      <div className={styles.innerNavigation}>
        <LogoLink logoSrc={logo} className={styles.logo} />
        {width && width > MOBILE_BREAK_POINT && getMenuButtons()}
        {width && width < MOBILE_BREAK_POINT && (
          <HamburgerButton
            isMobileMenuOpen={isMobileMenuOpen}
            clickHandler={() => setIsMobileMenuOpen((prevState) => !prevState)}
          />
        )}
        {width && width < MOBILE_BREAK_POINT && isMobileMenuOpen && (
          <div className={styles.mobileMenuLinks}>
            {!isHomePage && (
              <div className={styles.searchbarContainer}>
                <PublicSearchInput
                  placeholder={translate(
                    intl,
                    'HOMEPAGE.LEGAL_FIELDS_SECTION_SEARCH',
                  )}
                  endAdornment
                />
              </div>
            )}
            <CommonLinks setIsPlanModalOpen={setIsPlanModalOpen} />
          </div>
        )}
      </div>
      {isPlanModalOpen && (
        <PlanModal
          isOpen={isPlanModalOpen}
          onClose={() => setIsPlanModalOpen(false)}
          setIsPlanConfirmationModalOpen={setIsPlanConfirmationModalOpen}
          setPlanMonthsSelected={setPlanMonthsSelected}
          setIsFreeSelected={setIsFreeSelected}
        />
      )}
      {isPlanConfirmationModalOpen && (
        <PlanConfirmationModal
          isOpen={isPlanConfirmationModalOpen}
          onClose={() => handlePlanConfirmationModalClose()}
          planMonthsSelected={planMonthsSelected}
          setPlanMonthsSelected={setPlanMonthsSelected}
          setIsFreeSelected={setIsFreeSelected}
          isFreeSelected={isFreeSelected}
        />
      )}
    </header>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicNavigation);
