import axios from '../../config/Axios/axios-instance';
import {
  createLawyerProfileInfoFail,
  createLawyerProfileInfoStart,
  createLawyerProfileInfoSuccess,
  fetchLawyersListFail,
  fetchLawyersListStart,
  fetchLawyersListSuccess,
  fetchPublicLawyersListFail,
  fetchPublicLawyersListStart,
  fetchPublicLawyersListSuccess,
  fetchPublicLawyerFail,
  fetchPublicLawyerStart,
  fetchPublicLawyerSuccess,
  fetchSimilarLawyersFail,
  fetchSimilarLawyersStart,
  fetchSimilarLawyersSuccess,
  updateLawyerStatusFail,
  updateLawyerStatusStart,
  updateLawyerStatusSuccess,
  updateLawyerProfileInfoStart,
  updateLawyerProfileInfoSuccess,
  updateLawyerProfileInfoFail,
  requestAccountActivationFail,
  requestAccountActivationStart,
  requestAccountActivationSuccess,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { IntlShape } from 'react-intl';
import { LawyerType } from '../../domain/Lawyer';
import { getFormData } from '../../utility/form/formData';

const API_URL = '/lawyers';

export type CreateProfileInfoRequest = {
  languageIds: number[];
  education: string | null;
  price: string | null;
  lawFirm: string | null;
  lawyerType: LawyerType | null;
  profilePicture: File | null;
  categories: number[];
  title: string;
  description: string | null;
  workExperience: number;
  cityId: number | null;
  throughoutTheCountry: boolean;
  name: string;
  phone: string;
  email: string;
  policies: boolean;
};

export type UpdateProfileInfoRequest = {
  languageIds: number[];
  education: string | null;
  price: string | null;
  lawFirm: string | null;
  lawyerType: LawyerType | null;
  profilePicture: File | null;
  categories: number[];
  title: string;
  description: string | null;
  workExperience: number;
  cityId: number | null;
  throughoutTheCountry: boolean;
  name: string;
  phone: string;
  email: string;
  policies: boolean;
};

export type LawyerProfileStepTwoRequest = {
  title: string;
  description: string | null;
  workExperience: number;
  cityId: number | null;
  throughoutTheCountry: boolean;
  name: string;
  phone: string;
  email: string;
  policies: boolean;
};

export type PublicLawyerListParams = ListParams & {
  selectedCategory?: string;
};

export type AccountActivationRequest = {
  months: string;
  price: number;
  isFree?: boolean;
};

export type LawyerStatusUpdateRequest = {
  dateTo?: string;
};

export const fetchLawyersList =
  (params: ListParams, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchLawyersListStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchLawyersListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchLawyersListFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchPublicLawyer =
  (lawyerId: number, intl: IntlShape, isCurrentUser?: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(fetchPublicLawyerStart());
    return axios
      .get(
        !isCurrentUser
          ? `${API_URL}/public/${lawyerId}`
          : `${API_URL}/public/current-user`,
      )
      .then((response) => {
        dispatch(fetchPublicLawyerSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPublicLawyerFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchSimilarLawyers =
  (lawyerId: number) => (dispatch: Dispatch) => {
    dispatch(fetchSimilarLawyersStart());
    return axios
      .get(`${API_URL}/similar-lawyers/${lawyerId}`)
      .then((response) => {
        dispatch(fetchSimilarLawyersSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchSimilarLawyersFail(err?.response?.data?.message));
      });
  };

export const lawyerStatusUpdate =
  (id: number, inputs: LawyerStatusUpdateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateLawyerStatusStart());
    return axios
      .patch(`${API_URL}/${id}/status`, inputs)
      .then(() => {
        dispatch(updateLawyerStatusSuccess());
        showToast(translate(intl, 'SUCCESS.LAWYER_STATUS_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateLawyerStatusFail(err?.response?.data?.message));
      });
  };

export const createLawyerProfileInfo =
  (inputs: CreateProfileInfoRequest) => (dispatch: Dispatch) => {
    dispatch(createLawyerProfileInfoStart());
    return axios
      .post(API_URL, getFormData(inputs))
      .then(() => {
        dispatch(createLawyerProfileInfoSuccess());
      })
      .catch((err) => {
        dispatch(createLawyerProfileInfoFail(err?.response?.data?.message));
      });
  };

export const updateLawyerProfileInfo =
  (inputs: UpdateProfileInfoRequest) => (dispatch: Dispatch) => {
    dispatch(updateLawyerProfileInfoStart());
    return axios
      .patch(API_URL, getFormData(inputs))
      .then(() => {
        dispatch(updateLawyerProfileInfoSuccess());
      })
      .catch((err) => {
        dispatch(updateLawyerProfileInfoFail(err?.response?.data?.message));
      });
  };

export const fetchPublicLawyersList =
  (params: PublicLawyerListParams) => (dispatch: Dispatch) => {
    dispatch(fetchPublicLawyersListStart());
    return axios
      .get(`${API_URL}/public`, { params })
      .then((response) => {
        dispatch(fetchPublicLawyersListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPublicLawyersListFail(err?.response?.data?.message));
      });
  };

export const requestAccountActivation =
  (inputs: AccountActivationRequest) => (dispatch: Dispatch) => {
    dispatch(requestAccountActivationStart());
    return axios
      .post(`${API_URL}/account-activation`, inputs)
      .then(() => {
        dispatch(requestAccountActivationSuccess());
      })
      .catch((err) => {
        dispatch(requestAccountActivationFail(err?.response?.data?.message));
      });
  };
