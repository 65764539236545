export const FETCH_LANGUAGE_START = 'FETCH_LANGUAGE_START';
export const FETCH_LANGUAGE_SUCCESS = 'FETCH_LANGUAGE_SUCCESS';
export const FETCH_LANGUAGE_FAIL = 'FETCH_LANGUAGE_FAIL';

export const FETCH_LANGUAGES_START = 'FETCH_LANGUAGES_START';
export const FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS';
export const FETCH_LANGUAGES_FAIL = 'FETCH_LANGUAGES_FAIL';

export const CHANGE_LANGUAGE_STATUS_START = 'CHANGE_LANGUAGE_STATUS_START';
export const CHANGE_LANGUAGE_STATUS_SUCCESS = 'CHANGE_LANGUAGE_STATUS_SUCCESS';
export const CHANGE_LANGUAGE_STATUS_FAIL = 'CHANGE_LANGUAGE_STATUS_FAIL';

export const FETCH_LANGUAGES_LIST_START = 'FETCH_LANGUAGES_LIST_START';
export const FETCH_LANGUAGES_LIST_SUCCESS = 'FETCH_LANGUAGES_LIST_SUCCESS';
export const FETCH_LANGUAGES_LIST_FAIL = 'FETCH_LANGUAGES_LIST_FAIL';

export const CREATE_LANGUAGE_START = 'CREATE_LANGUAGE_START';
export const CREATE_LANGUAGE_SUCCESS = 'CREATE_LANGUAGE_SUCCESS';
export const CREATE_LANGUAGE_FAIL = 'CREATE_LANGUAGE_FAIL';

export const UPDATE_LANGUAGE_START = 'UPDATE_LANGUAGE_START';
export const UPDATE_LANGUAGE_SUCCESS = 'UPDATE_LANGUAGE_SUCCESS';
export const UPDATE_LANGUAGE_FAIL = 'UPDATE_LANGUAGE_FAIL';

export const DELETE_LANGUAGE_START = 'DELETE_LANGUAGE_START';
export const DELETE_LANGUAGE_SUCCESS = 'DELETE_LANGUAGE_SUCCESS';
export const DELETE_LANGUAGE_FAIL = 'DELETE_LANGUAGE_FAIL';

export const RESET_CREATE_LANGUAGE = 'RESET_CREATE_LANGUAGE';

export type LanguageActionTypes =
  | typeof FETCH_LANGUAGE_START
  | typeof FETCH_LANGUAGE_SUCCESS
  | typeof FETCH_LANGUAGE_FAIL
  | typeof FETCH_LANGUAGES_START
  | typeof FETCH_LANGUAGES_SUCCESS
  | typeof FETCH_LANGUAGES_FAIL
  | typeof CHANGE_LANGUAGE_STATUS_START
  | typeof CHANGE_LANGUAGE_STATUS_SUCCESS
  | typeof CHANGE_LANGUAGE_STATUS_FAIL
  | typeof FETCH_LANGUAGES_LIST_START
  | typeof FETCH_LANGUAGES_LIST_SUCCESS
  | typeof FETCH_LANGUAGES_LIST_FAIL
  | typeof CREATE_LANGUAGE_START
  | typeof CREATE_LANGUAGE_SUCCESS
  | typeof CREATE_LANGUAGE_FAIL
  | typeof UPDATE_LANGUAGE_START
  | typeof UPDATE_LANGUAGE_SUCCESS
  | typeof UPDATE_LANGUAGE_FAIL
  | typeof DELETE_LANGUAGE_START
  | typeof DELETE_LANGUAGE_SUCCESS
  | typeof DELETE_LANGUAGE_FAIL
  | typeof RESET_CREATE_LANGUAGE;
