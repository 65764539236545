import React, { ReactNode, useEffect } from 'react';
import styles from './AuthContainer.module.scss';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/Router/routes';
import { SignUpSectionType } from '../../../../pages/Public/SignUpPage/SignUpPage';
import cx from 'classnames';
import GoBackButton from '../../GoBackButton/GoBackButton';

type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  title: string;
  subTitle?: string;
  section: SignUpSectionType;
  onChangeSection: (section: SignUpSectionType) => void;
};

export const AuthContainer = ({
  children,
  isAuthenticated,
  title,
  subTitle,
  onChangeSection,
  section,
}: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.homepage);
    }
  }, [isAuthenticated]);

  return (
    <div className={styles.authContainer}>
      <div
        className={cx(styles.leftContainer, {
          [styles.passwordRemindSection]:
            section === SignUpSectionType.PASSWORD_REMIND,
        })}
      >
        {section === SignUpSectionType.PASSWORD_REMIND && (
          <GoBackButton
            onClick={() => onChangeSection(SignUpSectionType.LOGIN)}
            className={styles.goBackBtn}
          />
        )}
        <div className={styles.leftContainerInner}>
          <div className={styles.title}>{title}</div>
          {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(AuthContainer);
