import * as actionTypes from './actionTypes';
import { CategoryActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Category } from '../../domain/Category';

export type CategoryStateType = {
  categoriesList: ListResults<Category> | null;
  categoriesListLoading: boolean;
  categoriesListError: HttpError;
  categoriesListUpdateNeeded: boolean;
  categoryCreateLoading: boolean;
  categoryCreateError: HttpError;
  categoryCreateSuccess: boolean;
  createdCategory: Category | null;
  categoryUpdateLoading: boolean;
  categoryUpdateError: HttpError;
  categoryUpdateSuccess: boolean;
  category: Category | null;
  categoryLoading: boolean;
  categoryError: HttpError;
  categoryDeleteLoading: boolean;
  categoryDeleteError: HttpError;
  categoryDeleteSuccess: boolean;
  allCategories: Category[];
  allCategoriesLoading: boolean;
  allCategoriesError: HttpError | null;
  categoriesOptions: Category[];
  categoriesOptionsLoading: boolean;
  categoriesOptionsError: HttpError | null;
};

export type CategoryActionType = CategoryStateType & {
  type: CategoryActionTypes;
};

export const initialState: CategoryStateType = {
  categoriesList: null,
  categoriesListLoading: true,
  categoriesListError: null,
  categoriesListUpdateNeeded: false,
  categoryCreateLoading: false,
  categoryCreateError: null,
  categoryCreateSuccess: false,
  createdCategory: null,
  categoryUpdateLoading: false,
  categoryUpdateError: null,
  categoryUpdateSuccess: false,
  category: null,
  categoryLoading: false,
  categoryError: null,
  categoryDeleteLoading: false,
  categoryDeleteError: null,
  categoryDeleteSuccess: false,
  allCategories: [],
  allCategoriesLoading: false,
  allCategoriesError: null,
  categoriesOptions: [],
  categoriesOptionsLoading: false,
  categoriesOptionsError: null,
};

const fetchCategoriesListStart = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  categoriesListLoading: true,
});

const fetchCategoriesListSuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoriesListLoading: false,
  categoriesList: action.categoriesList,
  categoriesListUpdateNeeded: false,
});

const fetchCategoriesListFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoriesListLoading: false,
  categoriesListError: action.categoriesListError,
});

const fetchCategoryStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoryLoading: true,
});

const fetchCategorySuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryLoading: false,
  category: action.category,
});

const fetchCategoryFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryLoading: false,
  categoryError: action.categoryError,
});

const createCategoryStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoryCreateLoading: true,
});

const createCategorySuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryCreateLoading: false,
  categoryCreateError: null,
  categoryCreateSuccess: true,
  categoriesListUpdateNeeded: true,
});

const createCategoryFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryCreateLoading: false,
  categoryCreateError: action.categoryCreateError,
});

const updateCategoryStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoryUpdateLoading: true,
});

const updateCategorySuccess = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  categoryUpdateLoading: false,
  categoryUpdateSuccess: true,
  categoriesListUpdateNeeded: true,
});

const updateCategoryFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryUpdateLoading: false,
  categoryUpdateError: action.categoryUpdateError,
});

const deleteCategoryStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoryDeleteLoading: true,
});

const deleteCategorySuccess = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  categoryDeleteLoading: false,
  categoryDeleteSuccess: true,
  categoriesListUpdateNeeded: true,
});

const deleteCategoryFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryDeleteLoading: false,
  categoryDeleteError: action.categoryDeleteError,
});

const fetchAllCategoriesStart = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  allCategoriesLoading: true,
});

const fetchAllCategoriesSuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  allCategoriesLoading: false,
  allCategories: action.allCategories,
});

const fetchAllCategoriesFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  allCategoriesLoading: false,
  allCategoriesError: action.allCategoriesError,
});

const fetchCategoriesOptionsStart = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  categoriesOptionsLoading: true,
});

const fetchCategoriesOptionsSuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoriesOptionsLoading: false,
  categoriesOptions: action.categoriesOptions,
});

const fetchCategoriesOptionsFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoriesOptionsLoading: false,
  categoriesOptionsError: action.categoriesOptionsError,
});

const resetCategoryStore = (): CategoryStateType => ({
  ...initialState,
});

const completelyResetCategoryStore = (): CategoryStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CategoryActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CATEGORIES_LIST_START:
      return fetchCategoriesListStart(state);
    case actionTypes.FETCH_CATEGORIES_LIST_SUCCESS:
      return fetchCategoriesListSuccess(state, action);
    case actionTypes.FETCH_CATEGORIES_LIST_FAIL:
      return fetchCategoriesListFail(state, action);
    case actionTypes.FETCH_CATEGORY_START:
      return fetchCategoryStart(state);
    case actionTypes.FETCH_CATEGORY_SUCCESS:
      return fetchCategorySuccess(state, action);
    case actionTypes.FETCH_CATEGORY_FAIL:
      return fetchCategoryFail(state, action);
    case actionTypes.CREATE_CATEGORY_START:
      return createCategoryStart(state);
    case actionTypes.CREATE_CATEGORY_SUCCESS:
      return createCategorySuccess(state, action);
    case actionTypes.CREATE_CATEGORY_FAIL:
      return createCategoryFail(state, action);
    case actionTypes.UPDATE_CATEGORY_START:
      return updateCategoryStart(state);
    case actionTypes.UPDATE_CATEGORY_SUCCESS:
      return updateCategorySuccess(state);
    case actionTypes.UPDATE_CATEGORY_FAIL:
      return updateCategoryFail(state, action);
    case actionTypes.DELETE_CATEGORY_START:
      return deleteCategoryStart(state);
    case actionTypes.DELETE_CATEGORY_SUCCESS:
      return deleteCategorySuccess(state);
    case actionTypes.DELETE_CATEGORY_FAIL:
      return deleteCategoryFail(state, action);
    case actionTypes.FETCH_ALL_CATEGORIES_START:
      return fetchAllCategoriesStart(state);
    case actionTypes.FETCH_ALL_CATEGORIES_SUCCESS:
      return fetchAllCategoriesSuccess(state, action);
    case actionTypes.FETCH_ALL_CATEGORIES_FAIL:
      return fetchAllCategoriesFail(state, action);
    case actionTypes.FETCH_CATEGORIES_OPTIONS_START:
      return fetchCategoriesOptionsStart(state);
    case actionTypes.FETCH_CATEGORIES_OPTIONS_SUCCESS:
      return fetchCategoriesOptionsSuccess(state, action);
    case actionTypes.FETCH_CATEGORIES_OPTIONS_FAIL:
      return fetchCategoriesOptionsFail(state, action);
    case actionTypes.RESET_CATEGORY_STORE:
      return resetCategoryStore();
    case actionTypes.LOGOUT:
      return completelyResetCategoryStore();
    default:
      return state;
  }
};

export default reducer;
