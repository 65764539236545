import React from 'react';
import styles from './FooterCopyright.module.scss';
import ababaTechLogo from '../../../assets/footer/ababa-tech-logo-dark.png';
import { useIntl } from 'react-intl';
import { translate } from '../../../utility/messageTranslator/translate';

const FooterCopyright = () => {
  const intl = useIntl();

  return (
    <div className={styles.footerCopyright}>
      <div className={styles.footerCopyRightText}>
        {translate(intl, 'FOOTER_COPYRIGHT')}
      </div>
      <a
        href="https://ababa.tech/"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.footerCreatorLogo}
      >
        <img src={ababaTechLogo} alt="ababa.tech logo" />
      </a>
    </div>
  );
};

export default FooterCopyright;
