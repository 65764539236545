import React, { useEffect, useMemo } from 'react';
import styles from './PlanCard.module.scss';
import { Plan } from '../../PlanModal/PlanModal';
import checkIcon from '../../../../../assets/icons/check_icon.svg';
import checkIconWhite from '../../../../../assets/icons/check_icon_white.svg';
import { ReactSVG } from 'react-svg';
import Button from '../../../../../common/Button/Button';
import { translate } from '../../../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { StoreState } from '../../../../../config/StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import * as lawyerService from '../../../../../store/lawyer/service';
import { AccountActivationRequest } from '../../../../../store/lawyer/service';
import { User } from '../../../../../domain/User';

type Props = {
  plan: Plan;
  index: number;
  onClose: () => void;
  setIsPlanConfirmationModalOpen: (state: boolean) => void;
  onAccountActivationRequest: (inputs: AccountActivationRequest) => void;
  isSuccess: boolean;
  isLoading: boolean;
  setPlanMonthsSelected: (state: string) => void;
  currentUser: User | null;
  setIsFreeSelected: (state: boolean) => void;
};

const PlanCard = ({
  plan,
  index,
  onClose,
  setIsPlanConfirmationModalOpen,
  onAccountActivationRequest,
  isSuccess,
  isLoading,
  setPlanMonthsSelected,
  currentUser,
  setIsFreeSelected,
}: Props) => {
  const { months, price, pros } = plan;
  const intl = useIntl();

  const isBestValue = useMemo(() => {
    return index === 3;
  }, [index]);

  const isFreePlanDisabled = useMemo(() => {
    return index === 1 && currentUser?.lawyer?.freePlanRequested;
  }, [index]);

  const handleClick = () => {
    setPlanMonthsSelected(months);
    setIsFreeSelected(index === 1),
      onAccountActivationRequest({
        months: months,
        price: parseFloat(price.replace(',', '.')),
        isFree: index === 1,
      });
  };

  useEffect(() => {
    if (isSuccess) {
      setIsPlanConfirmationModalOpen(true);
      onClose();
    }
  }, [isSuccess]);

  return (
    <div
      className={cx(styles.cardContainer, {
        [styles.isBestValue]: isBestValue,
      })}
    >
      <div className={styles.months}>
        <span>{months}</span> {translate(intl, 'PLAN_MODAL.MONTHS')}
      </div>
      <div className={styles.divider} />
      <div className={styles.price}>{price}</div>
      <div className={styles.prosList}>
        {pros.map((item, index) => {
          return (
            <div key={`pros-${index}`} className={styles.pros}>
              <ReactSVG src={isBestValue ? checkIconWhite : checkIcon} /> {item}
            </div>
          );
        })}
      </div>
      {isBestValue && (
        <div className={styles.bestValueLabel}>
          {translate(intl, 'PLAN_MODAL.BEST_VALUE')}
        </div>
      )}
      <Button
        color="primary"
        onClick={() => handleClick()}
        isLoadingButton
        isLoading={isLoading}
        isDisabled={isFreePlanDisabled}
      >
        {translate(intl, 'PLAN_MODAL.BUY')}
      </Button>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isLoading: state.lawyer.requestAccountActivationLoading,
  isSuccess: state.lawyer.requestAccountActivationSuccess,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onAccountActivationRequest: (inputs: AccountActivationRequest) =>
    dispatch(lawyerService.requestAccountActivation(inputs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanCard);
