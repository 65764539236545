import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './CommonLinks.module.scss';
import cx from 'classnames';
import { routes } from '../../../config/Router/routes';
import { translate } from '../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import Button from '../../../common/Button/Button';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../../store/auth/service';
import { User } from '../../../domain/User';
import { Roles } from '../../../domain/Role';
import _ from 'lodash';
import { AccountActivationStatus } from '../../../domain/Lawyer';
import moment from 'moment';

export type Props = {
  isAuthenticated: boolean;
  onLogout: () => void;
  currentUser: User | null;
  setIsPlanModalOpen: (state: boolean) => void;
};

export interface LinkItem {
  label: string;
  to: string;
  roles: Roles[];
}

const CommonLinks = ({
  isAuthenticated,
  onLogout,
  currentUser,
  setIsPlanModalOpen,
}: Props) => {
  const intl = useIntl();

  const COMMON_LINKS: LinkItem[] = [
    {
      label: translate(intl, 'COMMON_LINKS.LAWYERS_LIST'),
      to: routes.publicLawyers.list,
      roles: isAuthenticated ? [Roles.ADMIN, Roles.OWNER] : [],
    },
    {
      label: translate(intl, 'COMMON_LINKS.LOGIN'),
      to: routes.login,
      roles: [],
    },
    {
      label: translate(intl, 'COMMON_LINKS.FILL_REQUEST'),
      to: routes.createEnquiry,
      roles: [],
    },
    {
      label: translate(intl, 'COMMON_LINKS.ADMIN_AREA'),
      to: routes.users.list,
      roles: [Roles.ADMIN],
    },
    {
      label: translate(intl, 'COMMON_LINKS.EDIT_PROFILE'),
      to: routes.profile.profile,
      roles: [Roles.OWNER],
    },
  ];

  const filteredLinks = useMemo(() => {
    return _.filter(COMMON_LINKS, (link) => {
      if (!isAuthenticated) {
        return _.isEmpty(link.roles);
      }

      return _.includes(link.roles, _.get(currentUser, 'role'));
    });
  }, [currentUser?.id, isAuthenticated]);

  const getAccountStatus = () => {
    if (!currentUser || !currentUser.lawyer) {
      return;
    }

    switch (currentUser.lawyer.accountStatus) {
      case AccountActivationStatus.ACTIVE:
        return (
          <div
            className={styles.activeAccountStatus}
            dangerouslySetInnerHTML={{
              __html: translate(intl, 'ACCOUNT_STATUS.ACTIVE').replace(
                ':value',
                moment(currentUser.lawyer.activeAccountEnd).format(
                  'YYYY-MM-DD',
                ),
              ),
            }}
          />
        );
      case AccountActivationStatus.PENDING:
        return (
          <div
            className={styles.accountStatus}
            dangerouslySetInnerHTML={{
              __html: translate(intl, 'ACCOUNT_STATUS.PENDING'),
            }}
          />
        );
      case AccountActivationStatus.INACTIVE:
        return (
          <Button color="primary" onClick={() => setIsPlanModalOpen(true)}>
            {translate(intl, 'PUBLIC_NAVIGATION.ACTIVATE_ACCOUNT')}
          </Button>
        );
    }
  };

  return (
    <div className={styles.navLinkContainer}>
      {filteredLinks.map(({ label, to }: LinkItem) => (
        <NavLink
          key={label}
          to={to}
          className={({ isActive }) =>
            cx(styles.navLink, {
              [styles.activeNavLink]: isActive,
            })
          }
        >
          {label}
        </NavLink>
      ))}

      {isAuthenticated && (
        <div className={styles.buttonsContainer}>
          {currentUser &&
            currentUser.role === Roles.OWNER &&
            getAccountStatus()}
          <Button
            className={styles.navigationButton}
            type="button"
            onClick={() => onLogout()}
            buttonVariant="outlined"
          >
            {translate(intl, 'COMMON_LINK.LOGOUT')}
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonLinks);
