import React from 'react';
import styles from './Popover.module.scss';

type PopoverMenuItem = {
  label: string;
  iconPath: string;
  onClick: () => void;
};

type Props = {
  isOpen: boolean;
  menuItems: PopoverMenuItem[];
  onClose: () => void;
};

const Popover = ({ isOpen, menuItems, onClose }: Props) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.popover}>
      <ul className={styles.menu}>
        {menuItems.map((item, index) => (
          <li
            key={index}
            onClick={() => {
              item.onClick();
              onClose();
            }}
          >
            <img
              src={item.iconPath}
              alt={item.label}
              className={styles.menuIcon}
            />
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Popover;
