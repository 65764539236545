import React from 'react';
import styles from './PlanModal.module.scss';
import Modal from '../../../../common/Modal/Modal';
import { translate } from '../../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import PlanCard from './PlanCard/PlanCard';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  setIsPlanConfirmationModalOpen: (state: boolean) => void;
  setPlanMonthsSelected: (state: string) => void;
  setIsFreeSelected: (state: boolean) => void;
};

export type Plan = {
  months: string;
  price: string;
  pros: string[];
};

const PlanModal = ({
  isOpen,
  onClose,
  setIsPlanConfirmationModalOpen,
  setPlanMonthsSelected,
  setIsFreeSelected,
}: Props) => {
  const intl = useIntl();

  const PLANS: Plan[] = Array.from({ length: 3 }, (_, planIndex) => ({
    months: translate(intl, `PLAN_MODAL.${planIndex}_PLAN_MONTHS`),
    price: translate(intl, `PLAN_MODAL.${planIndex}_PLAN_PRICE`),
    pros: Array.from({ length: 3 }, (_, index) =>
      translate(intl, `PLAN_MODAL.${planIndex}_PLAN_${index + 1}_PROS`),
    ),
  }));

  return (
    <Modal onClose={onClose} isOpen={isOpen} className={styles.planModal}>
      <div className={styles.plansContainer}>
        <div className={styles.title}>
          {translate(intl, 'PLAN_MODAL.TITLE')}
        </div>
        <div className={styles.planCardsContainer}>
          {PLANS.map((plan, index) => (
            <PlanCard
              plan={plan}
              index={index + 1}
              key={`plan-card-${index}`}
              onClose={onClose}
              setIsPlanConfirmationModalOpen={setIsPlanConfirmationModalOpen}
              setPlanMonthsSelected={setPlanMonthsSelected}
              setIsFreeSelected={setIsFreeSelected}
            />
          ))}
        </div>
        <div className={styles.contactsContainer}>
          <div
            className={styles.contacts}
            dangerouslySetInnerHTML={{
              __html: translate(intl, 'PLAN_MODAL.INFO_CONTACT'),
            }}
          />
          <div className={styles.workingHours}>
            {translate(intl, 'PLAN_MODAL.INFO_WORKING_HOURS')}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PlanModal;
