import React from 'react';
import styles from './SignUpSection.module.scss';
import SocialLogin from '../SocialLogin/SocialLogin';
import LoginForm from '../LoginForm/LoginForm';
import { useIntl } from 'react-intl';
import { translate } from '../../../../utility/messageTranslator/translate';
import RegisterForm from '../RegisterForm/RegisterForm';
import PasswordRemindForm from '../PasswordRemindForm/PasswordRemindForm';
import { SignUpSectionType } from '../../../../pages/Public/SignUpPage/SignUpPage';

type Props = {
  section: SignUpSectionType;
  onChangeSection: (section: SignUpSectionType) => void;
};

export const SignUpSection = ({ section, onChangeSection }: Props) => {
  const intl = useIntl();

  const getSection = () => {
    if (section === SignUpSectionType.REGISTER) {
      return <RegisterForm onChangeSection={onChangeSection} />;
    }

    if (section === SignUpSectionType.PASSWORD_REMIND) {
      return <PasswordRemindForm />;
    }

    return <LoginForm onChangeSection={onChangeSection} />;
  };

  return (
    <div className={styles.loginSectionContainer}>
      {getSection()}
      {section !== SignUpSectionType.PASSWORD_REMIND && (
        <section className={styles.separatorWrapper}>
          <div className={styles.separator}>
            <hr className={styles.line} />
            <span className={styles.word}>
              {translate(intl, 'SIGN_UP_SECTION.SEPARATOR')}
            </span>
          </div>
        </section>
      )}
      {section !== SignUpSectionType.PASSWORD_REMIND && (
        <SocialLogin isRegistration={section === SignUpSectionType.REGISTER} />
      )}
    </div>
  );
};

export default SignUpSection;
