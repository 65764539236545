import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Lawyer } from '../../domain/Lawyer';
import { LawyerProfileStepTwoRequest } from './service';
import { PublicLawyer } from '../../domain/PublicLawyer';

export const fetchLawyersListStart = () => ({
  type: actionTypes.FETCH_LAWYERS_LIST_START,
});

export const fetchLawyersListSuccess = (lawyersList: ListResults<Lawyer>) => ({
  type: actionTypes.FETCH_LAWYERS_LIST_SUCCESS,
  lawyersList,
});

export const fetchLawyersListFail = (lawyersListError: HttpError) => ({
  type: actionTypes.FETCH_LAWYERS_LIST_FAIL,
  lawyersListError,
});

export const fetchPublicLawyersListStart = () => ({
  type: actionTypes.FETCH_PUBLIC_LAWYERS_LIST_START,
});

export const fetchPublicLawyersListSuccess = (
  publicLawyersList: ListResults<PublicLawyer>,
) => ({
  type: actionTypes.FETCH_PUBLIC_LAWYERS_LIST_SUCCESS,
  publicLawyersList,
});

export const fetchPublicLawyersListFail = (
  publicLawyersListError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_LAWYERS_LIST_FAIL,
  publicLawyersListError,
});

export const fetchPublicLawyerStart = () => ({
  type: actionTypes.FETCH_PUBLIC_LAWYER_START,
});

export const fetchPublicLawyerSuccess = (
  publicLawyer: ListResults<PublicLawyer>,
) => ({
  type: actionTypes.FETCH_PUBLIC_LAWYER_SUCCESS,
  publicLawyer,
});

export const fetchPublicLawyerFail = (publicLawyerError: HttpError) => ({
  type: actionTypes.FETCH_PUBLIC_LAWYER_FAIL,
  publicLawyerError,
});

export const resetPublicLawyerStore = () => ({
  type: actionTypes.RESET_PUBLIC_LAWYER_STORE,
});

export const fetchSimilarLawyersStart = () => ({
  type: actionTypes.FETCH_SIMILAR_LAWYERS_START,
});

export const fetchSimilarLawyersSuccess = (
  similarLawyers: ListResults<PublicLawyer>,
) => ({
  type: actionTypes.FETCH_SIMILAR_LAWYERS_SUCCESS,
  similarLawyers,
});

export const fetchSimilarLawyersFail = (similarLawyersError: HttpError) => ({
  type: actionTypes.FETCH_SIMILAR_LAWYERS_FAIL,
  similarLawyersError,
});

export const resetSimilarLawyersStore = () => ({
  type: actionTypes.RESET_SIMILAR_LAWYERS_STORE,
});

export const resetLawyersStore = () => ({
  type: actionTypes.RESET_LAWYERS_STORE,
});

export const updateLawyerStatusStart = () => ({
  type: actionTypes.UPDATE_LAWYER_STATUS_START,
});

export const updateLawyerStatusSuccess = () => ({
  type: actionTypes.UPDATE_LAWYER_STATUS_SUCCESS,
});

export const updateLawyerStatusFail = (lawyerStatusUpdateError: string) => ({
  type: actionTypes.UPDATE_LAWYER_STATUS_FAIL,
  lawyerStatusUpdateError,
});

export const createLawyerProfileInfoStart = () => ({
  type: actionTypes.CREATE_LAWYER_PROFILE_INFO_START,
});

export const createLawyerProfileInfoSuccess = () => ({
  type: actionTypes.CREATE_LAWYER_PROFILE_INFO_SUCCESS,
});

export const createLawyerProfileInfoFail = (
  createLawyerProfileInfoError: HttpError,
) => ({
  type: actionTypes.CREATE_LAWYER_PROFILE_INFO_FAIL,
  createLawyerProfileInfoError,
});

export const updateLawyerProfileInfoStart = () => ({
  type: actionTypes.UPDATE_LAWYER_PROFILE_INFO_START,
});

export const updateLawyerProfileInfoSuccess = () => ({
  type: actionTypes.UPDATE_LAWYER_PROFILE_INFO_SUCCESS,
});

export const updateLawyerProfileInfoFail = (
  updateLawyerProfileInfoError: HttpError,
) => ({
  type: actionTypes.UPDATE_LAWYER_PROFILE_INFO_FAIL,
  updateLawyerProfileInfoError,
});

export const resetLawyerProfileInfoStore = () => ({
  type: actionTypes.RESET_LAWYER_PROFILE_INFO_STORE,
});

export const setProfileInfoStepOneCategories = (
  profileInfoStepOneCategories: number[],
) => ({
  type: actionTypes.SET_PROFILE_INFO_STEP_ONE_CATEGORIES,
  profileInfoStepOneCategories,
});

export const setProfileInfoStepTwo = (
  profileInfoStepTwo: LawyerProfileStepTwoRequest,
) => ({
  type: actionTypes.SET_PROFILE_INFO_STEP_TWO,
  profileInfoStepTwo,
});

export const requestAccountActivationStart = () => ({
  type: actionTypes.REQUEST_ACCOUNT_ACTIVATION_START,
});

export const requestAccountActivationSuccess = () => ({
  type: actionTypes.REQUEST_ACCOUNT_ACTIVATION_SUCCESS,
});

export const requestAccountActivationFail = (
  requestAccountActivationError: HttpError,
) => ({
  type: actionTypes.REQUEST_ACCOUNT_ACTIVATION_FAIL,
  requestAccountActivationError,
});
