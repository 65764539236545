import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { JwtToken } from '../Axios/axios-instance';
import { logout, selectLocale } from '../../store/auth/actions';
import * as languageService from '../../store/language/service';
import { Language } from '../../domain/Language';
import { DEFAULT_LOCALE } from '../constants';
import { Locale } from '../../domain/Translation';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { User } from '../../domain/User';
import { SignUpSectionType } from '../../pages/Public/SignUpPage/SignUpPage';
import { Roles } from '../../domain/Role';

const SignUpPage = React.lazy(
  () => import('../../pages/Public/SignUpPage/SignUpPage'),
);

const RegistrationConfirmationPage = React.lazy(
  () =>
    import(
      '../../pages/Public/RegistrationConfirmationPage/RegistrationConfirmationPage'
    ),
);

const CategoryListPage = React.lazy(
  () =>
    import('../../pages/Admin/Category/CategoriesListPage/CategoriesListPage'),
);

const CityListPage = React.lazy(
  () => import('../../pages/Admin/City/CitiesListPage/CitiesListPage'),
);

const UsersListPage = React.lazy(
  () => import('../../pages/Admin/User/UsersListPage/UsersListPage'),
);

const UserCreatePage = React.lazy(
  () => import('../../pages/Admin/User/UserCreatePage/UserCreatePage'),
);

const UserEditPage = React.lazy(
  () => import('../../pages/Admin/User/UserEditPage/UserEditPage'),
);

const TranslationsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);

const EnquiriesListPage = React.lazy(
  () => import('../../pages/Admin/Enquiry/EnquiriesListPage/EnquiriesListPage'),
);

const DynamicPage = React.lazy(
  () => import('../../pages/Public/DynamicPage/DynamicPage'),
);
const HomePage = React.lazy(
  () => import('../../pages/Public/HomePage/HomePage'),
);

const PublicLawyerListPage = React.lazy(
  () => import('../../pages/Public/PublicLawyerListPage/PublicLawyerListPage'),
);

const ComingSoonPage = React.lazy(
  () => import('../../pages/Public/ComingSoonPage/ComingSoonPage'),
);

const LanguagesListPage = React.lazy(
  () => import('../../pages/Admin/Language/LanguagesListPage'),
);

const SingleLawyerPage = React.lazy(
  () => import('../../pages/Public/SingleLawyerPage/SingleLawyerPage'),
);

const PasswordResetPage = React.lazy(
  () => import('../../pages/Public/PasswordResetPage/PasswordResetPage'),
);

const MyProfilePage = React.lazy(
  () => import('../../pages/Public/MyProfilePage/MyProfilePage'),
);

const ProfileEditPage = React.lazy(
  () => import('../../pages/Public/ProfileEditPage/ProfileEditPage'),
);

const EnquiryPage = React.lazy(
  () => import('../../pages/Public/EnquiryPage/EnquiryPage'),
);

const PasswordChangePage = React.lazy(
  () => import('../../pages/Public/PasswordChangePage/PasswordChangePage'),
);

const PublicEnquiriesPage = React.lazy(
  () => import('../../pages/Public/PublicEnquiriesPage/PublicEnquiriesPage'),
);

const SinglePublicEnquiryPage = React.lazy(
  () =>
    import(
      '../../pages/Public/SinglePublicEnquiryPage/SinglePublicEnquiryPage'
    ),
);

const LawyersPage = React.lazy(
  () => import('../../pages/Admin/Lawyer/LawyersListPage/LawyerListPage'),
);

const PrivacyPolicyPage = React.lazy(
  () => import('../../pages/Public/PrivacyPolicyPage/PrivacyPolicyPage'),
);

const RulesPage = React.lazy(
  () => import('../../pages/Public/RulesPage/RulesPage'),
);

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  isCurrentUserLoading: boolean;
  refreshTokenLoading: boolean;
  onFetchCurrentUser: () => void;
  onRefreshToken: () => void;
  jwtToken: string | null;
  lastActionAt: moment.Moment | null;
  onLogout: () => void;
  onLanguageFetch: (locale: string) => void;
  language: Language | null;
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  currentUser: User | null;
  onLanguagesInit: () => void;
  profileCreateSuccess: boolean;
};

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  isCurrentUserLoading,
  onFetchCurrentUser,
  refreshTokenLoading,
  onRefreshToken,
  jwtToken,
  lastActionAt,
  onLogout,
  onLanguageFetch,
  language,
  selectedLocale,
  onSelectLocale,
  currentUser,
  onLanguagesInit,
  profileCreateSuccess,
}: Props) => {
  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (
      difference.asMinutes() < 5 &&
      differenceLastAction.asMinutes() < 5 &&
      !refreshTokenLoading
    ) {
      onRefreshToken();
    }

    const timeout = setTimeout(() => {
      onLogout();
    }, difference.asMilliseconds());

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
  }, []);

  useEffect(() => {
    onFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated, profileCreateSuccess]);

  useEffect(() => {
    moment.locale(DEFAULT_LOCALE);
    onLanguageFetch(DEFAULT_LOCALE);
    onLanguagesInit();
    onSelectLocale(DEFAULT_LOCALE);
  }, []);

  const mappedTranslations = language?.translations?.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const adminRoutes =
    currentUser &&
    currentUser.role === Roles.ADMIN &&
    isAuthenticated &&
    jwtToken;

  const getRoutes = () => {
    if (!isAuthenticated) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.login} />}
          />
          <Route path={routes.login} element={<SignUpPage />} />
          <Route
            path={routes.register}
            element={<SignUpPage sectionType={SignUpSectionType.REGISTER} />}
          />
          <Route
            path={routes.remind}
            element={
              <SignUpPage sectionType={SignUpSectionType.PASSWORD_REMIND} />
            }
          />
          <Route
            path={routes.registrationConfirmation}
            element={<RegistrationConfirmationPage />}
          />
          <Route path={routes.resetPassword} element={<PasswordResetPage />} />
          <Route path={routes.homepage} element={<HomePage />} />
          <Route path={routes.createEnquiry} element={<EnquiryPage />} />
          <Route
            path={routes.publicLawyers.lawyer}
            element={<SingleLawyerPage />}
          />
          <Route
            path={routes.publicLawyers.list}
            element={<PublicLawyerListPage />}
          />
          <Route path={routes.privacyPolicy} element={<PrivacyPolicyPage />} />
          <Route path={routes.comingSoon} element={<ComingSoonPage />} />
          <Route path={routes.rules} element={<RulesPage />} />
          <Route path="*" element={<Navigate to={routes.login} />} />
        </>
      );
    }

    return (
      <>
        {adminRoutes && (
          <>
            <Route
              path={routes.admin}
              element={<Navigate replace to={routes.users.list} />}
            />
            <Route
              path={routes.categories.list}
              element={<CategoryListPage />}
            />
            <Route
              path={routes.enquiries.list}
              element={<EnquiriesListPage />}
            />
            <Route path={routes.cities.list} element={<CityListPage />} />
            <Route path={routes.users.create} element={<UserCreatePage />} />
            <Route path={routes.users.edit} element={<UserEditPage />} />
            <Route path={routes.users.list} element={<UsersListPage />} />
            <Route path={routes.translations} element={<TranslationsPage />} />
            <Route path={routes.languages} element={<LanguagesListPage />} />
            <Route path={routes.lawyers.list} element={<LawyersPage />} />
            <Route
              path={routes.privacyPolicy}
              element={<PrivacyPolicyPage />}
            />
          </>
        )}

        <Route path={routes.homepage} element={<HomePage />} />
        <Route path={routes.dynamicPage} element={<DynamicPage />} />
        {currentUser?.role !== Roles.ADMIN && (
          <Route path={routes.profile.profile} element={<MyProfilePage />} />
        )}
        {currentUser?.role !== Roles.ADMIN && (
          <Route path={routes.profile.edit} element={<ProfileEditPage />} />
        )}
        <Route
          path={routes.publicEnquiry.publicEnquiriesPage}
          element={<PublicEnquiriesPage />}
        />
        <Route
          path={routes.publicEnquiry.singlePublicEnquiryPage}
          element={<SinglePublicEnquiryPage />}
        />
        <Route
          path={routes.publicLawyers.list}
          element={<PublicLawyerListPage />}
        />
        {!currentUser?.googleId && (
          <Route
            path={routes.passwordChangePage}
            element={<PasswordChangePage />}
          />
        )}
        <Route
          path={routes.publicLawyers.lawyer}
          element={<SingleLawyerPage />}
        />
        <Route path={routes.privacyPolicy} element={<PrivacyPolicyPage />} />
        <Route path={routes.rules} element={<RulesPage />} />
        {currentUser?.role === Roles.ADMIN ? (
          <Route path="*" element={<Navigate to={routes.users.list} />} />
        ) : (
          <Route path="*" element={<Navigate to={routes.profile.profile} />} />
        )}
      </>
    );
  };

  return (
    <BrowserRouter basename="/">
      {isInitCompleted && !isCurrentUserLoading && language ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="en"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Layout>
                <Routes>{getRoutes()}</Routes>
              </Layout>
            </Suspense>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  isCurrentUserLoading: state.user.currentUserLoading,
  refreshTokenLoading: state.auth.refreshTokenLoading,
  jwtToken: state.auth.jwtToken,
  lastActionAt: state.auth.lastStoreActionAt,
  language: state.language.language,
  selectedLocale: state.auth.selectedLocale,
  currentUser: state.user.currentUser,
  profileCreateSuccess: state.lawyer.createLawyerProfileInfoSuccess,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLanguageFetch: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLogout: () => dispatch(logout()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
