import React, { useState, useEffect, useRef } from 'react';
import styles from './ProfileButton.module.scss';
import profileIcon from '../../assets/header/profile-circle.svg';
import profileIconSecond from '../../assets/header/profile-circle-2.svg';
import editIcon from '../../assets/header/edit.svg';
import logoutIcon from '../../assets/header/logout.svg';
import Button from '../Button/Button';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { logout } from '../../store/auth/actions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import Popover from '../Popover/Popover';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import adminArea from '../../assets/header/admin-area.svg';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../config/Router/routes';

export type Props = {
  onLogout: () => void;
  currentUser: User | null;
};

const ProfileButton = ({ onLogout, currentUser }: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node) &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setIsPopoverOpen(false);
      }
    };

    if (isPopoverOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isPopoverOpen]);

  const menuItems = [
    {
      label: translate(intl, 'PROFILE_BUTTON.ACTION_ADMIN_AREA'),
      iconPath: adminArea,
      onClick: () => navigate(routes.admin),
      roles: [Roles.ADMIN],
    },
    {
      label: translate(intl, 'PROFILE_BUTTON.ACTION_EDIT_PROFILE'),
      iconPath: profileIconSecond,
      onClick: () => navigate(routes.profile.profile),
      roles: [Roles.OWNER],
    },
    {
      label: translate(intl, 'PROFILE_BUTTON.ACTION_INQUIRIES'),
      iconPath: editIcon,
      onClick: () => navigate(routes.publicEnquiry.publicEnquiriesPage),
      roles: [Roles.OWNER],
    },
    {
      label: translate(intl, 'PROFILE_BUTTON.ACTION_LOGOUT'),
      iconPath: logoutIcon,
      onClick: () => onLogout(),
      roles: [Roles.ADMIN, Roles.OWNER],
    },
  ];

  const getFilteredMenuItems = () => {
    if (currentUser) {
      return menuItems.filter((menuItem) =>
        menuItem.roles.includes(currentUser.role),
      );
    }

    return menuItems.filter((menuItem) => menuItem.roles.includes(Roles.OWNER));
  };

  return (
    <div className={styles.profileButton} ref={buttonRef}>
      <Button
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        className={styles.button}
      >
        <img src={profileIcon} />
        {translate(intl, 'PROFILE_BUTTON.PROFILE')}
      </Button>
      <div ref={popoverRef}>
        <Popover
          isOpen={isPopoverOpen}
          menuItems={getFilteredMenuItems()}
          onClose={() => setIsPopoverOpen(false)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileButton);
