export const CREATE_PUBLIC_ENQUIRY_START = 'CREATE_PUBLIC_ENQUIRY_START';
export const CREATE_PUBLIC_ENQUIRY_SUCCESS = 'CREATE_PUBLIC_ENQUIRY_SUCCESS';
export const CREATE_PUBLIC_ENQUIRY_FAIL = 'CREATE_PUBLIC_ENQUIRY_FAIL';

export const FETCH_PUBLIC_ENQUIRIES_START = 'FETCH_PUBLIC_ENQUIRIES_START';
export const FETCH_PUBLIC_ENQUIRIES_SUCCESS = 'FETCH_PUBLIC_ENQUIRIES_SUCCESS';
export const FETCH_PUBLIC_ENQUIRIES_FAIL = 'FETCH_PUBLIC_ENQUIRIES_FAIL';

export const FETCH_PUBLIC_ENQUIRY_START = 'FETCH_PUBLIC_ENQUIRY_START';
export const FETCH_PUBLIC_ENQUIRY_SUCCESS = 'FETCH_PUBLIC_ENQUIRY_SUCCESS';
export const FETCH_PUBLIC_ENQUIRY_FAIL = 'FETCH_PUBLIC_ENQUIRY_FAIL';

export const FETCH_ENQUIRIES_LIST_START = 'FETCH_ENQUIRIES_LIST_START';
export const FETCH_ENQUIRIES_LIST_SUCCESS = 'FETCH_ENQUIRIES_LIST_SUCCESS';
export const FETCH_ENQUIRIES_LIST_FAIL = 'FETCH_ENQUIRIES_LIST_FAIL';

export const CHANGE_ENQUIRY_STATUS_START = 'CHANGE_ENQUIRY_STATUS_START';
export const CHANGE_ENQUIRY_STATUS_SUCCESS = 'CHANGE_ENQUIRY_STATUS_SUCCESS';
export const CHANGE_ENQUIRY_STATUS_FAIL = 'CHANGE_ENQUIRY_STATUS_FAIL';

export const RESET_PUBLIC_ENQUIRY = 'RESET_PUBLIC_ENQUIRY';
export const RESET_PUBLIC_ENQUIRIES = 'RESET_PUBLIC_ENQUIRIES';
export const RESET_ENQUIRY_STORE = 'RESET_ENQUIRY_STORE';

export const LOGOUT = 'LOGOUT';

export type EnquiryActionTypes =
  | typeof FETCH_PUBLIC_ENQUIRY_START
  | typeof FETCH_PUBLIC_ENQUIRY_SUCCESS
  | typeof FETCH_PUBLIC_ENQUIRY_FAIL
  | typeof FETCH_ENQUIRIES_LIST_START
  | typeof FETCH_ENQUIRIES_LIST_SUCCESS
  | typeof FETCH_ENQUIRIES_LIST_FAIL
  | typeof CREATE_PUBLIC_ENQUIRY_START
  | typeof CREATE_PUBLIC_ENQUIRY_SUCCESS
  | typeof CREATE_PUBLIC_ENQUIRY_FAIL
  | typeof FETCH_PUBLIC_ENQUIRIES_START
  | typeof FETCH_PUBLIC_ENQUIRIES_SUCCESS
  | typeof FETCH_PUBLIC_ENQUIRIES_FAIL
  | typeof CHANGE_ENQUIRY_STATUS_START
  | typeof CHANGE_ENQUIRY_STATUS_SUCCESS
  | typeof CHANGE_ENQUIRY_STATUS_FAIL
  | typeof RESET_PUBLIC_ENQUIRY
  | typeof RESET_PUBLIC_ENQUIRIES
  | typeof RESET_ENQUIRY_STORE
  | typeof LOGOUT;
