import React, { ChangeEvent, ReactNode, useEffect, useRef } from 'react';
import styles from './FileInput.module.scss';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { Asset } from '../../domain/Asset';
import { HttpError } from '../../config/Axios/axios-instance';
import Dropzone from 'react-dropzone';
import { Clear } from '@mui/icons-material';
import { ReactSVG } from 'react-svg';
import uploadDocumentIcon from '../../assets/icons/document-upload.svg';

type Props = {
  name: string;
  multiple?: boolean;
  value: File | File[] | string | string[] | undefined;
  onFileChange?: (event: ChangeEvent<any>) => void;
  onFileDropped?: (name: string, files: File[]) => void;
  onImageDelete?: (name: string, value: string | File) => void;
  onSetValidationErrors?: (error: HttpError) => void;
  maxFileCount?: number;
  errors?: Array<string>;
  label?: string | ReactNode;
  helperText?: string;
  asset?: Asset | null;
};

const FileInput = ({
  onFileChange,
  value,
  name,
  maxFileCount,
  multiple,
  errors,
  label,
  helperText,
  asset,
  onImageDelete,
  onFileDropped,
  onSetValidationErrors,
}: Props) => {
  const intl = useIntl();

  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadCLick = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  };

  const getIcon = () => {
    if (errors && errors.length > 0) {
      return uploadDocumentIcon;
    }

    if (value && (!errors || errors.length === 0)) {
      return uploadDocumentIcon;
    }

    return uploadDocumentIcon;
  };

  const renderAddFileButton = () => {
    const hasValue = Array.isArray(value) ? !!value.length : !!value;
    const uploadedCount = Array.isArray(value) && value.length;

    return (
      <div className={styles.innerFileSelectContainer}>
        <ReactSVG
          className={cx(styles.innerFileIcon, {
            [styles.errorFile]: errors && errors.length > 0,
            [styles.successFile]: hasValue && (!errors || errors.length === 0),
          })}
          src={getIcon()}
        />
        <span className={styles.innerFileSelectLabel}>
          {hasValue &&
          (!errors || errors.length === 0) &&
          (!multiple || (maxFileCount && uploadedCount === maxFileCount))
            ? translate(intl, 'INPUTS.READY_TO_UPLOAD')
            : translate(
                intl,
                multiple ? 'INPUTS.UPLOAD_IMAGES' : 'INPUTS.UPLOAD_IMAGE',
              )}
        </span>
      </div>
    );
  };

  const renderImage = (value: File | string, index: number) => {
    return (
      <div key={`image-${index}`} className={styles.imageContainer}>
        {multiple && (
          <div className={styles.delete}>
            <Clear
              color="inherit"
              onClick={() => {
                onImageDelete?.(name, value);

                if (uploadInputRef?.current) {
                  uploadInputRef.current.value = '';
                }
              }}
            />
          </div>
        )}
        {value instanceof File ? (
          <img src={URL.createObjectURL(value)} alt={value.name} />
        ) : (
          <img src={value.toString()} alt={value?.toString() || ''} />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (
      multiple &&
      maxFileCount &&
      Array.isArray(value) &&
      maxFileCount < value.length &&
      onSetValidationErrors
    ) {
      onSetValidationErrors([
        {
          field: name,
          message: translate(intl, 'VALIDATION.TOO_MANY_FILES'),
        },
      ]);
    }
  }, [value, multiple, maxFileCount]);

  return (
    <Dropzone
      multiple
      onDrop={(acceptedFiles) => onFileDropped?.(name, acceptedFiles)}
    >
      {({ getRootProps, getInputProps }) => (
        <div className={styles.fileFieldContainer} {...getRootProps()}>
          <h5>{label}</h5>
          <div className={styles.fileField}>
            <input
              color="primary"
              type="file"
              onChange={onFileChange}
              ref={uploadInputRef}
              name={name}
              multiple={multiple}
              {...getInputProps()}
            />
            <div className={styles.fileContainer}>
              <div
                className={styles.fileSelectedContainer}
                onClick={handleUploadCLick}
              >
                {value && !multiple
                  ? renderImage(value as File | string, 0)
                  : renderAddFileButton()}
              </div>
            </div>
          </div>
          <div className={styles.label}>
            {errors &&
              errors.map((error, index) => (
                <div className={styles.fileError} key={`file-error-${index}`}>
                  {error}
                </div>
              ))}
            {helperText && <p>{helperText}</p>}
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default FileInput;
